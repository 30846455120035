import React, {ReactNode} from 'react';
import styled from 'styled-components';
import { useAnalytic } from '../../../analytics';
import { useTweekValue } from 'react-tweek';

const CallLink = styled.a`
  text-decoration: none;
  color: currentColor;
`;

export const ClickToCall: React.FC<{children?: ReactNode}> = (props) => {
  const analytics = useAnalytic();
  const supportNumber = useTweekValue(
    'nashville/smb/security_bundle/support/numberD',
    '(844) 776-1960',
  );

  const clickHandler = () => {
    return () => {
      analytics.dispatcher
        .createScoped('ClickToCall Component')
        .dispatch('ClickToCall Click');
    };
  };

  return (
    <CallLink onClick={clickHandler()} href={`tel:${supportNumber}`} data-testid={'call-link'}>
      {props.children || supportNumber}
    </CallLink>
  );
};
