import styled from 'styled-components';

export const LayoutContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2.5rem 0;

  @media (max-width: 768px) {
    margin: 1rem 0;
  }
`;
