import { MessageType } from 'roarr';

import { LogWriter } from '../writers/LogWriter';
import {getEnabledWriters} from "./getEnabledWriters";

let _writers: LogWriter[];

const getWriters = async () => {
  if (!_writers) {
    _writers = await getEnabledWriters();
  }
  return _writers;
};

export const combinedWrite = async (message: MessageType) => {
  const writers = await getWriters();
  await Promise.all(writers.map((w) => w.write(message)));
};
