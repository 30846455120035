import { gql } from 'apollo-boost';

export const GET_NETWORKS = gql`
  query GetNetworks {
    getNetworks {
      originId
      name
      ipAddress
      prefixLength
      isDynamic
      isVerified
      status
    }
  }
`;
