// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { combinedWrite } from './combinedLogging';

// This function is not really testable, jest doesn't recognize globalThis
/* istanbul ignore next */
export const initializeLogging = () => {
  globalThis.ROARR = globalThis.ROARR || {};
  globalThis.ROARR.write = (message) => combinedWrite(JSON.parse(message));
};
