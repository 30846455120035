import React, { useState } from 'react';
import styled from 'styled-components';
import '@soluto-private/atomic-ui-library';
import '@soluto-private/asurion-design-assets-asurion-black/asurion-black.css';
import '@soluto-private/atomic-ui-library-react';
import Fullstory from 'react-fullstory'; 

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ErrorBanner, TerminalError } from '../Error';
import breakpoints from '../../../common/helpers/breakpoints';
import { FAQ } from '../../../faq/index';
import { WhatsCovered } from '../../../whatsCovered/components';
import {GraphQLProvider} from "../../../graphql";
import ExpertSetup from "../../../setup/components/NetworkSetup/ExpertSetup";
import {getTweekRepository, TweekContext} from "../../../tweek";
import {AppError, AppErrorContext, buildConfigContext, ConfigContext} from "../../../common";
import {GlobalStyle} from "../GlobalStyle";
import {
  BASE_PATH,
  DEVICE_DETAIL_PATH,
  DEVICE_SETUP_PATH,
  ERROR_PATH,
  FAQ_PATH,
  NETWORK_SETUP_PATH,
  UPGRADE_PATH,
  WHATS_COVERED_PATH,
} from "../../../navigation";
import {Dashboard} from "../../../dashboard";
import {AuthenticationWrapper} from "../../../authentication";
import {Navbar} from "../Navbar";
import {DeviceSetup} from "../../../setup";
import {DeviceDetail, UpgradeLicense} from "../../../device";
import {Footer} from "../Footer";

const AppContainer = styled.main`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
`;

const LayoutContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  min-width: ${breakpoints.minWidth};
`;

const App: React.FC = () => {
  const tweekRepo = getTweekRepository();
  const [error, setError] = useState<AppError | undefined>();

  const setGeneralError = () => {
    setError(AppError.General);
  };

  const setTerminalError = () => {
    setError(AppError.Terminal);
  };

  const clearError = () => {
    setError(undefined);
  };

  const appErrorContext: AppErrorContext = {
    error,
    setGeneralError,
    setTerminalError,
    clearError,
  };

  return (
    <AppContainer>
      <GlobalStyle />
      <Fullstory org={process.env.REACT_APP_FULLSTORY_ORG} />

      <TweekContext.Provider value={tweekRepo}>
        <AppErrorContext.Provider value={appErrorContext}>
          <ConfigContext.Provider value={buildConfigContext()}>
            <BrowserRouter>
              <AuthenticationWrapper>
                <GraphQLProvider>
                  <LayoutContainer>
                    <Navbar />

                    <ErrorBanner />

                    <div>
                      <Switch>
                        <Route
                          component={TerminalError}
                          exact
                          path={ERROR_PATH}
                        />

                        <Route component={Dashboard} exact path={BASE_PATH} />

                        <Route component={FAQ} exact path={FAQ_PATH} />

                        <Route
                          component={WhatsCovered}
                          exact
                          path={WHATS_COVERED_PATH}
                        />

                        <Route
                          component={DeviceSetup}
                          exact
                          path={DEVICE_SETUP_PATH}
                        />

                        <Route
                          component={DeviceDetail}
                          exact
                          path={DEVICE_DETAIL_PATH}
                        />

                        <Route
                          component={ExpertSetup}
                          exact
                          path={NETWORK_SETUP_PATH}
                        />

                        <Route
                          component={UpgradeLicense}
                          exact
                          path={UPGRADE_PATH}
                        />

                        <Redirect to="/" />
                      </Switch>
                    </div>

                    <Footer />
                  </LayoutContainer>
                </GraphQLProvider>
              </AuthenticationWrapper>
            </BrowserRouter>
          </ConfigContext.Provider>
        </AppErrorContext.Provider>
      </TweekContext.Provider>
    </AppContainer>
  );
};

export default App;
