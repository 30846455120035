import React from 'react';
import { SectionNavBar, Constrainer, QuestionsAndAnswers } from '../../common';
import { useTweekValue } from 'react-tweek';
import defaultFaqs from '../defaultFaqs.json';

export const Faq: React.FC = () => {
  const title = useTweekValue(
    'nashville/smb/security_bundle/faq/title',
    'Frequently Asked Questions',
  );

  const questionsAndAnswers = useTweekValue(
    'nashville/smb/security_bundle/faq/faqs',
    defaultFaqs,
  );

  return (
    <div>
      <SectionNavBar />
      <Constrainer maxWidth={'900px'}>
        <QuestionsAndAnswers
          title={title}
          questionsAndAnswers={questionsAndAnswers}
        />
      </Constrainer>
    </div>
  );
};

export default Faq;
