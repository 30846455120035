import React, { useState } from 'react';
import 'styled-components/macro';
import { Button, Input } from '@soluto-private/atomic-ui-library-react';
import CopyToClipboard from 'react-copy-to-clipboard';

type LicenseFieldProps = {
  licenseKey: string;
};

export const LicenseField: React.FC<LicenseFieldProps> = ({ licenseKey }) => {
  const [isCopied, setIsCopied] = useState(false);

  return (
    <div>
      <label>
        <strong>License Key</strong>
      </label>

      <div
        css={`
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          padding: 4px 0 0;
        `}
      >
        <div
          css={`
            flex: 1 1 auto;
            min-width: 350px;
            max-width: 400px;
            margin-right: 1rem;
            margin-bottom: 1rem;
          `}
        >
          <Input
            value={licenseKey}
            ariaLabelledBy="License Key"
            disabled={true}
          />

          <label
            css={`
              margin-top: 4px;
            `}
          >
            Use this key when prompted.
          </label>
        </div>

        <div
          css={`
            display: flex;
            align-items: center;
          `}
        >
          <CopyToClipboard text={licenseKey} onCopy={() => setIsCopied(true)}>
            <Button>Copy</Button>
          </CopyToClipboard>

          <span
            css={`
              min-width: 60px;
              margin-left: 1rem;
            `}
          >
            {isCopied && 'Copied!'}
          </span>
        </div>
      </div>
    </div>
  );
};
