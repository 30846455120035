import React from 'react';
import styled from 'styled-components';

const CheckboxContainer = styled.div<Partial<CheckboxProps>>`
  display: inline-block;
  vertical-align: middle;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })<CheckboxProps>`
border: 0;
position: absolute;
opacity: 0;
height: 20px;
width: 20px;
`;

const Icon = styled.svg`
  fill: white;
`;

const StyledCheckbox = styled.div<Partial<CheckboxProps>>`
  display: inline-block;
  width: 20px;
  height: 20px;
  background: ${props => (props.checked ? '#000000' : 'transparent')};
  border: 1px solid black;
  border-radius: 3px;
  transition: all 150ms;

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')}
  }
`;

type CheckboxProps = {
    checked: boolean;
    onChange: (event: unknown) => void;
};

export const Checkbox = ({checked, onChange}: CheckboxProps) => (
      <CheckboxContainer className="checkbox">
      <HiddenCheckbox checked={checked} onChange={onChange} data-testid="threatCheckbox"/>
      <StyledCheckbox checked={checked}>
      <Icon
          width="13"
          height="13"
          viewBox="0 0 13 13"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path 
            d="M13.692 1.78356L6.77909 12.9578C6.55647 13.3672 6.13521 13.6297 5.66956 13.6491C5.20391 13.6685 4.76228 13.442 4.50636 13.0525L0.245001 7.65477C-0.114898 7.19961 -0.0740733 6.54642 0.339698 6.13962C0.771063 5.75379 1.42348 5.75379 1.85485 6.13962L5.45333 9.35931L11.8927 0.457798C12.2465 -0.0173595 12.906 -0.141009 13.4079 0.173707C13.883 0.527499 14.0067 1.18696 13.692 1.68886V1.78356Z"
          />
        </Icon>
      </StyledCheckbox>
      </CheckboxContainer>
);
