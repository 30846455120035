import { isWriterEnabled } from './isWriterEnabled';
import * as analyticsWriters from '../writers';
import { AnalyticsWriter } from '../writers/AnalyticsWriter';

/* eslint-disable @typescript-eslint/consistent-type-assertions */
export const getEnabledWriters = async (
  /* istanbul ignore next */ writers = (<unknown>(
    analyticsWriters
  )) as AnalyticsWriter[],
): Promise<AnalyticsWriter[]> => {
  const analyticWriters: AnalyticsWriter[] = Object.keys(writers)
      .map((key: string) => writers[key as keyof typeof writers] as AnalyticsWriter)
      .filter((writer) => writer.isEnabled);

  const enabledWriters = await Promise.all(
    analyticWriters.map(isWriterEnabled),
  );
  return analyticWriters.filter((_, index) => enabledWriters[index]);
};
