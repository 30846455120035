import { CognitoAuthSession } from 'amazon-cognito-auth-js';
import * as moment from 'moment';

import { client } from './client';
import { User } from '../models';

export const getCurrentUser = (
  authSession?: CognitoAuthSession,
): User | undefined => {
  if (!client.isUserSignedIn()) {
    return undefined;
  } else {
    const session = authSession || client.getSignInUserSession();
    const payload: {sub: string, 'cognito:username': string, externalId: string} = session.getIdToken().decodePayload() as {sub: string, 'cognito:username': string, externalId: string};
    return {
      sub: payload.sub,
      username: payload['cognito:username'],
      externalId: payload.externalId,
      accessToken: session.getAccessToken().getJwtToken(),
      idToken: session.getIdToken().getJwtToken(),
      refreshToken: session.getRefreshToken().getToken(),
      expiration: moment
        .unix(session.getIdToken().getExpiration())
        .toISOString(),
    };
  }
};
