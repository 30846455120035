import { useAnalytics } from 'react-shisell';

/**
 * This custom hook is where we could put any boilerplate logic/tracking code to
 * reduce duplication across the components.
 */
export const useAnalytic = () => {
  const analytics = useAnalytics();
  return analytics;
};
