import {
  phoneOutgoing,
  userQuestionMark,
  profileAvatar,
} from '@soluto-private/asurion-design-assets-asurion-black';
import { Icon as AtomicIcon } from '@soluto-private/atomic-ui-library-react';
import umbrellaCheck from '../resources/umbrella-check-mark.svg';
import close from '../resources/close.svg';
import closeWhite from '../resources/close-white.svg';
import checkCircle2 from '../resources/check-circle-2.svg';
import React from 'react';
import interfaceAlertCircle from '../resources/interface-alert-circle.svg';
import addCircle1 from '../resources/add-circle-1.svg';
import checkShield from '../resources/check-shield.svg';
import block from '../resources/block.svg';
import lightBulbCircle from '../resources/light-bulb-circle.svg';
import styled from 'styled-components';
import appleLaptop from '../resources/device/AppleLaptop.svg';
import laptop from '../resources/device/Laptop.svg';
import tablet from '../resources/device/Tablet.svg';
import windowsLaptop from '../resources/device/WindowsLaptop.svg';
import classnames from 'classnames';
import cloudy from '../resources/cloudy.svg';
import laptop2 from '../resources/laptop-2.svg';
import userQuestionMark2 from '../resources/user-question-mark.svg';
import phone from '../resources/phone.svg';
import chatBubble from '../resources/chat-bubble.svg';
import questionBubble from '../resources/question-bubble.svg';
import shield from '../resources/shield.svg';
import plus from '../resources/plus.svg';
import minus from '../resources/minus.svg';
import warning from '../resources/warning.svg';

const IconContainer = styled.div`
  .auto {
    --icon-height: auto;
    --icon-width: auto;
  }

  .medium {
    --icon-height: 2rem;
    --icon-width: 2rem;
  }

  .marginRight {
    --icon-margin-right: 1rem;
  }
`;

const iconMap = {
  chatBubble,
  phoneOutgoing,
  userQuestionMark,
  umbrellaCheck,
  close,
  closeWhite,
  checkCircle2,
  interfaceAlertCircle,
  addCircle1,
  checkShield,
  block,
  lightBulbCircle,
  appleLaptop,
  laptop,
  tablet,
  windowsLaptop,
  profileAvatar,
  cloudy,
  laptop2,
  userQuestionMark2,
  phone,
  questionBubble,
  shield,
  plus,
  minus,
  warning,
};

type IconProps = {
  name: string;
  size?: string;
  alt?: string;
  disabled?: boolean;
};

export const Icon: React.FC<IconProps> = ({
  name,
  size,
  alt,
  disabled,
}: IconProps) => {
  const sizeClass = classnames({
    large: size === 'large',
    medium: size === 'medium',
    auto: size === 'auto',
    marginRight: size === 'marginRight',
  });

  return (
    <IconContainer data-testid='icon'>
      <AtomicIcon
        src={iconMap[name as keyof typeof iconMap]}
        class={sizeClass}
        alt={alt}
        disabled={disabled}
      />
    </IconContainer>
  );
};
