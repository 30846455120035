import { gql } from "apollo-boost";

export const GET_REQUESTS_BY_TIME_RANGE = gql`
  query GetRequestsByTimeRange {
    getRequestsByTimeRange {
      totalRequests
      countsByType {
        all
        allowed
        blocked
      }
      timestamp
      time
      date
    }
  }
`;
