import React, { useState, useEffect, useCallback } from 'react';
import { default as styled } from 'styled-components';
import { ErrorIconSVG } from './ErrorIconSVG';
import { WarningIconSVG } from './WarningIconSVG';
import { InfoIconSVG } from './InfoIconSVG';
import { SuccessIconSVG } from './SuccessIconSVG';
import { Icon } from '../Icon';
export type ToastType = {
  id: number;
  description: string;
  title: string;
  type: 'danger' | 'success' | 'warning' | 'info';
};
const ToastContainer = styled.div`
  font-size: 1em;
  box-sizing: border-box;
  position: fixed;
  bottom: 100px;
  right: 2em;
  width: 330px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
`;

const ToastContentContainer = styled.div`
  min-height: 50px;
  padding: 16px 32px 16px 8px;
  border-radius: 3px;
  box-shadow: 0 0 10px #999;
  color: #000;
  opacity: 0.9;
  background: #fff no-repeat 15px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
  display: flex;
  justify-content: space-between;

  &:hover {
    box-shadow: 0 0 12px #fff;
    opacity: 1;
    cursor: pointer;
  }
`;

const ToastImgContainer = styled.div`
  float: left;
  margin-right: 15px;
  svg {
    width: 32px;
    height: 32px;
  }
`;

const ToastTextContainer = styled.div``;

const ToastTitle = styled.p`
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 6px;
`;

const ToastMessage = styled.div``;

const ToastCloseButton = styled.button`
  position: absolute;
  top: 1em;
  right: 1em;
  border: none;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.8;
  line-height: 1;
  font-size: 16px;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
`;

const IconMap: Record<string, React.FC> = {
  danger: ErrorIconSVG,
  warning: WarningIconSVG,
  info: InfoIconSVG,
  success: SuccessIconSVG,
};

const bgColorMap: Record<string, string> = {
  danger: '#d9534f',
  warning: '#f0ad4e',
  info: '#5bc0de',
  success: '#5cb85c',
};

export const Toast: React.FC<{ toastList: ToastType[] }> = ({ toastList }) => {
  const [list, setList] = useState(toastList);
  const deleteToast = useCallback(
    (id: number) => {
      const listItemIndex = list.findIndex((e) => e.id === id);
      const toastListItem = toastList.findIndex((e) => e.id === id);
      list.splice(listItemIndex, 1);
      toastList.splice(toastListItem, 1);
      setList([...list]);
    },
    [toastList, list],
  );

  // set the list on load
  useEffect(() => {
    setList([...toastList]);
  }, [toastList]);

  // remove the toast after 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      if (toastList.length && list.length) {
        deleteToast(toastList[0].id);
      }
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [toastList, list, deleteToast]);

  return (
    <div data-testid='first-child-toast'>
      <ToastContainer>
        {list.map((toast, i) => {
          const ToastIcon = IconMap[toast.type];

          return (
            <ToastContentContainer
              key={i}
              style={{ backgroundColor: bgColorMap[toast.type] }}
            >
              <ToastCloseButton
                data-testid="toast-close-button"
                onClick={() => deleteToast(toast.id)}
              >
                <Icon name="closeWhite" size="small" />
              </ToastCloseButton>

              <ToastImgContainer>
                <ToastIcon />
              </ToastImgContainer>

              <ToastTextContainer>
                <ToastTitle>{toast.title}</ToastTitle>
                <ToastMessage>{toast.description}</ToastMessage>
              </ToastTextContainer>
            </ToastContentContainer>
          );
        })}
      </ToastContainer>
    </div>
  );
};
