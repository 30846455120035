import { isWriterEnabled } from './isWriterEnabled';
import * as logsWriters from '../writers';
import { LogWriter } from '../writers/LogWriter';

/* eslint-disable @typescript-eslint/consistent-type-assertions */
export const getEnabledWriters = async (
  /* istanbul ignore next */ writers = (<unknown>logsWriters) as LogWriter[],
): Promise<LogWriter[]> => {
  const logWriters: LogWriter[] = Object.keys(writers)
    .map((key) => writers[key as keyof typeof logWriters] as LogWriter)
    .filter((writer) => writer.isEnabled);

  const enabledWriters = await Promise.all(logWriters.map(isWriterEnabled));
  return logWriters.filter((_, index) => enabledWriters[index]);
};
