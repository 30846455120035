import React from 'react';
import defaultWhatsCoveredQuestions from '../defaultWhatsCoveredQuestions.json';
import {Constrainer, QuestionsAndAnswers, SectionNavBar} from "../../common";

export const WhatsCovered: React.FC = () => {
  return (
    <div>
      <SectionNavBar />
      <Constrainer maxWidth={'900px'}>
        <QuestionsAndAnswers
          title="What's Covered"
          questionsAndAnswers={defaultWhatsCoveredQuestions}
        />
      </Constrainer>
    </div>
  );
};

export default WhatsCovered;
