import React, { useState } from 'react';
import { default as styled } from 'styled-components';

import { Heading, Button } from '@soluto-private/atomic-ui-library-react';
import {TweekContext} from "../../../tweek";
import {ClickToCall, Modal, SectionNavBar} from "../../../common";

const useTweekValue = TweekContext.useTweekValue;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 3rem 3.5rem 9rem;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoContainer = styled.div`
  max-width: 60rem;
  margin-top: -3rem;
`;

export const ExpertSetup: React.FC = () => {
  const [showModal, setShowModal] = useState(false);

  const onClose = () => {
    setShowModal(false);
  };
  
  const callNowBtn = useTweekValue(
    'nashville/smb/security_bundle/support/call_now',
    'Call now',
  );

  const setupSecurityTitle = useTweekValue(
    'nashville/smb/security_bundle/setup/network_setup/expert_setup/setup_title',
    'Setup internet security',
  );

  const setupSecurityInfoText = useTweekValue(
    'nashville/smb/security_bundle/setup/network_setup/expert_setup/setup_info',
    `We use network protection, which blocks unwanted domains, 
    suspicious websites and cloud applications. Our experts can 
    help you to block additional website categories like gambling 
    and social media.`,
  );

  const expertSetup = useTweekValue(
    'nashville/smb/security_bundle/setup/network_setup/expert_setup/title',
    'An Expert will help you complete your setup.',
  );

  const supportNumber = useTweekValue(
    'nashville/smb/security_bundle/support/number',
    '(844) 776-1960',
  );

  const handleCallNowClick = () => {
    setShowModal(true);
  };

  return (
    <div data-testid='expert-setup'>
      <Modal
        heading={'Connect with an Expert'}
        isOpen={showModal}
        onClose={onClose}
      >
        <ClickToCall>{supportNumber}</ClickToCall>
      </Modal>
      <SectionNavBar></SectionNavBar>
      <Container>
        <Heading size={5}>{setupSecurityTitle}</Heading>
        <InfoContainer>
          <p>{setupSecurityInfoText}</p>
        </InfoContainer>
        <ActionContainer>
          <Heading size={4}>{expertSetup}</Heading>
          <Button onClick={handleCallNowClick}>{callNowBtn}</Button>
        </ActionContainer>
      </Container>
    </div>
  );
};

export default ExpertSetup;
