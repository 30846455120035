import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {Icon} from "../Icon";

const InfoContainer = styled.div`
  position: relative;
  max-width: 400px;
`;

const InfoIconWrapper = styled.div`
  position: absolute;
  top: -16px;
  width: 100%;
  text-align: center;
  z-index: 1;
`;

const InfoBoxMain = styled.div`
  border: 0.2em solid var(--primary-border-color);
  box-sizing: border-box;
  border-radius: 0.5em;
  width: 100%;
  text-align: center;
  padding: 2rem 1rem;
  line-height: 160%;
`;

export const InfoBox: React.FC<{ children?: ReactNode ,isError?: boolean }> = ({
  children,
  isError,
}) => (
  <InfoContainer data-testid={'first-child-info-box'}>
    <InfoIconWrapper>
      <Icon name={isError ? 'warning' : 'lightBulbCircle'} size="auto" />
    </InfoIconWrapper>

    <InfoBoxMain>{children}</InfoBoxMain>
  </InfoContainer>
);
