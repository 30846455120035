import React from 'react';
import styled from 'styled-components';
import { useTweekValue } from 'react-tweek';

import { Button } from '@soluto-private/atomic-ui-library-react';
import { useHistory } from 'react-router-dom';
import { useAnalytics } from 'react-shisell';
import { NETWORK_SETUP_PATH } from '../../../navigation';

// This is a bit of a hack because the heading lives in the parent but we want
// it to look like the "Advanced settings" button lives on a row with it.
const Container = styled.div`
  margin-top: -4.5rem;

  @media (max-width: 500px) {
    margin-top: -1rem;
  }
`;

const NetworkHeading = styled.div`
  max-width: 90em;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 500px) {
    justify-content: flex-start;
  }
`;

const InfoContainer = styled.div`
  max-width: 31.75em;
`;

export const AdvancedNetworkSettingsView: React.FC = () => {
  const history = useHistory();
  const analytics = useAnalytics();

  const message = useTweekValue(
    'nashville/smb/security_bundle/dashboard/network/advanced_settings/text',
    'We use network protection, which blocks unwanted domains, suspicious websites, and cloud applications. Our Experts can help you to block additional website categories like gambling and social media.',
  );

  const advancedSettingsButton = useTweekValue(
    'nashville/smb/security_bundle/dashboard/network/add_network/button',
    'Advanced settings',
  );

  const onActivationButtonClick = () => {
    history.push(NETWORK_SETUP_PATH);
    analytics.dispatcher
      .createScoped('Advanced Settings')
      .dispatch('Advanced Network Settings Clicked');
  };

  return (
    <Container data-testid='first-child-advanced-network-settings-view'>
      <NetworkHeading>
        <Button onClick={onActivationButtonClick}>
          {advancedSettingsButton}
        </Button>
      </NetworkHeading>

      <InfoContainer>
        <p>{message}</p>
      </InfoContainer>
    </Container>
  );
};
