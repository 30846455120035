import { analytics } from 'react-shisell';

import { combinedWrite } from './combinedAnalytics';

export const initializeAnalytics = () => {
  analytics.setWriter(combinedWrite);
  analytics.transformDispatcher(
    /*istanbul ignore next*/ (dispatcher) =>
      dispatcher
        .withExtra('ApplicationName', 'SecurityBundle')
        .withExtra('ApplicationVersion', process.env.REACT_APP_RELEASE_VERSION),
  );
};
