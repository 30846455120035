import { AnalyticsWriter } from '../writers/AnalyticsWriter';

export const isWriterEnabled = async (
  writer: AnalyticsWriter,
): Promise<boolean> => {
  try {
    return await writer.isEnabled();
  } catch {
    return Promise.resolve(false);
  }
};
