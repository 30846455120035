import { gql } from 'apollo-boost';

export const GET_USER_INTEGRATIONS_BY_ENROLLMENT_ID = gql`
  query GetUserIntegrationsByEnrollmentId($enrollmentId: String) {
    getUserIntegrationsByEnrollmentId(enrollmentId: $enrollmentId) {
      id
      enrollmentId
      externalId
      sub
      vendor
      type
      authType
      setupComplete
      metadata {
        tenantId
        tenantIdKey
      }
      createdAt
      updatedAt
    }
  }
`;
