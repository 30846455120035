import React from 'react';
import styled from 'styled-components/macro';
import '@soluto-private/atomic-ui-library-react';

import Constrainer from '../../../common/components/Layout/Constrainer';
import { Link } from '@soluto-private/atomic-ui-library-react';
import {ClickToCall} from "../../../common";

const Container = styled.div`
  font-size: 11px;
  color: var(--secondary-font-color);
  background-color: var(--footer-background);
  --link-color: #fff;
`;

const PipeContainer = styled.span`
  margin: 0 1rem;
`;

export const Footer: React.FC = () => (
  <Container data-testid={'footer'}>
    <Constrainer>
      <div
        css={`
          font-size: 16px;
        `}
      >
        <span>Need help? Call us. </span>
        <ClickToCall>
          <strong>(844) 776-1960</strong>
        </ClickToCall>
      </div>

      <p
        css={`
          margin-top: 2rem;
          font-size: 11px;
        `}
      >
        © Asurion 1992–2020. All Rights Reserved.
      </p>

      <div
        css={`
          --link-padding: 0;
        `}
      >
        <Link href="https://www.asurion.com/terms-conditions/">
          Terms & Conditions
        </Link>

        <PipeContainer>|</PipeContainer>

        <Link href="https://www.asurion.com/privacy-policy/">
          Privacy Policy
        </Link>
      </div>
    </Constrainer>
  </Container>
);
