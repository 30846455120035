import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Heading } from '@soluto-private/atomic-ui-library-react';

import { TableRow, ActivityTableFooter } from './index';
import { Icon, Spinner } from '../../../common';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 33.25em;
  border: 0.06em solid var(--secondary-border-color);
  border-radius: 0.4em;
`;

const TopContainer = styled.div`
  width: 100%;
  color: var(--secondary-font-color);
  background-color: var(--secondary-background-color);
  border-radius: 0.3em 0.3em 0 0;
  padding: 1px 2rem;
  box-sizing: border-box;
  --heading-margin: 1rem 0;
  flex: 0 0 auto;
`;

const MiddleContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const BottomContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  padding: 1rem;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const EmptyThreatsMessage = styled.div`
  flex: 1 1 auto;
  padding: 2rem;
  display: flex;
  align-items: center;
  color: var(--tertiary-font-color);
  box-sizing: border-box;
`;

const Checkmark = styled.div`
  margin-left: 0.5rem;

  svg {
    fill: var(--color-safe-state);
  }
`;

export enum TableType {
  Detected = 'detected',
  Allowed = 'allowed',
  Blocked = 'blocked',
}

export enum ThreatStatusType {
  Allowed = 'ALLOWED',
  Detected = 'DETECTED',
  Blocked = 'BLOCKED',
}

export type FormattedThreat = {
  name: string;
  fileStatus: ThreatStatusType;
};

const emptyTableMessage: Record<TableType, string> = {
  [TableType.Detected]: 'Zero threats detected',
  [TableType.Allowed]: 'Zero threats detected',
  [TableType.Blocked]: 'Zero threats detected',
};

export type ActivityTableProps = {
  currentActivityNumber: number;
  headingMessage?: string;
  tableType: TableType;
  threats: FormattedThreat[];
  isLoading?: boolean;
  onIsAllowed?: (params: {
    threatNames: string[];
    areAllowed: boolean;
  }) => void;
  isError?: boolean;
  hideActions?: boolean;
};

export const ActivityTable: React.FC<ActivityTableProps> = ({
  currentActivityNumber,
  headingMessage,
  tableType,
  threats,
  isLoading,
  onIsAllowed,
  isError,
  hideActions,
}) => {
  const [checkedThreats, setCheckedThreats] = useState<FormattedThreat[]>([]);
  const handleThreatCheck = (threat: FormattedThreat) => (bool: boolean) => {
    if (bool) {
      setCheckedThreats([...checkedThreats, threat]);
    } else {
      setCheckedThreats(checkedThreats.filter((ct) => ct.name !== threat.name));
    }
  };

  const handleIsAllowed = (isAllowed: boolean) => {
    if (onIsAllowed) {
      onIsAllowed({
        threatNames: checkedThreats.map((ct) => ct.name),
        areAllowed: isAllowed,
      });
    }
  };

  return (
    <Container data-testid='first-child-activity-table'>
      <TopContainer>
        <Heading size={6} data-testid={`${headingMessage} number`}>
          {currentActivityNumber}
        </Heading>
        <Heading size={3} class="light">
          {headingMessage}
        </Heading>
      </TopContainer>

      <MiddleContainer>
        {isLoading ? (
          <div
            css={`
              height: 100%;
              margin-top: 3rem;
            `}
          >
            <Spinner />
          </div>
        ) : (
          <>
            {isError && (
              <EmptyThreatsMessage>
                <span
                  css={`
                    font-style: italic;
                  `}
                >
                  Try refreshing the page to load information
                </span>
              </EmptyThreatsMessage>
            )}

            {!isError && threats.length > 0 && (
              <Table>
                <thead>
                  <TableRow isHeadRow={true} threatName="NAME" />
                </thead>

                <tbody>
                  {threats.map((threat, i) => (
                    <TableRow
                      key={`ThreatItem-${threat.name}-${i}`}
                      threatName={threat.name}
                      isThreatChecked={checkedThreats.some(
                        (ct) => ct.name === threat.name,
                      )}
                      onThreatCheck={handleThreatCheck(threat)}
                      hideCheckbox={hideActions}
                    />
                  ))}
                </tbody>
              </Table>
            )}

            {!isError && !threats.length && (
              <EmptyThreatsMessage>
                <Heading size={4}>{emptyTableMessage[tableType]}</Heading>
                <Checkmark>
                  <Icon name="checkCircle2" />
                </Checkmark>
              </EmptyThreatsMessage>
            )}
          </>
        )}
      </MiddleContainer>

      <BottomContainer>
        {!isError && !hideActions && threats.length > 0 && (
          <ActivityTableFooter
            tableType={tableType}
            onIsAllowed={handleIsAllowed}
            isButtonDisabled={checkedThreats.length === 0}
          />
        )}
      </BottomContainer>
    </Container>
  );
};
