import * as React from 'react';

export const SuccessIconSVG: React.FC = (props) => {
  return (
    <svg width={32} height={32} {...props} data-testid='success-icon-svg'>
      <title>{'background'}</title>
      <path fill="none" d="M-1-1h582v402H-1z" />
      <g>
        <title>{'Layer 1'}</title>
        <path
          fill="#fff"
          d="M16 0C7.164 0 0 7.164 0 16s7.164 16 16 16 16-7.164 16-16S24.836 0 16 0zm-2.48 23.383L6.158 16.02l2.828-2.828 4.533 4.535 9.617-9.617 2.828 2.828L13.52 23.383z"
        />
      </g>
    </svg>
  );
};
