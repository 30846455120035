import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowLeft } from '../resources/fillableSvg/arrow-left.svg';
import { ReactComponent as ChevronRight } from '../resources/fillableSvg/chevron-right.svg';
import classnames from 'classnames';

const iconMap = {
  arrowLeft: <ArrowLeft />,
  chevronRight: <ChevronRight />,
};

type IconProps = {
  name: string;
  fill?: string;
  size?: string;
};

const SvgContainer = styled.div<Partial<IconProps>>`
  height: var(--icon-height, 1.5rem);
  width: var(--icon-height, 1.5rem);
  vertical-align: var(--icon-vertical-align);

  &.marginRight {
    margin-right: 1rem;
  }

  fill: ${(props) => (props.fill ? props.fill : 'currentColor')};
`;

/**
 * For svg icons that could be different colors or the color needs to dynamically
 * change (ie: hover state)
 */
export const FillableIcon: React.FC<IconProps> = ({
  name,
  fill,
  size,
}: IconProps) => {
  const sizeClass = classnames({
    marginRight: size === 'marginRight',
  });
  return (
    <SvgContainer className={sizeClass} fill={fill} data-testid='fillable-icon'>
      {iconMap[name as keyof typeof iconMap]}
    </SvgContainer>
  );
};
