import { gql } from 'apollo-boost';

export const GET_DEVICE_THREATS = gql`
  query GetDeviceThreats($deviceId: ID!) {
    getDeviceThreats(deviceId: $deviceId) {
      pageNumber
      pageSize
      totalPages
      totalNumberOfItems
      pageItems {
        classification
        dateFound
        deviceName
        fileStatus
        name
        sha256
        subClassification
      }
    }
  }
`;
