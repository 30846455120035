import React from 'react';
import styled from 'styled-components';
import { Button } from '@soluto-private/atomic-ui-library-react';
import { TableType } from './index';
import { Icon } from '../Icon';

const ActivityFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const DisableWrapper = styled.div<{ isDisabled: boolean }>`
  opacity: ${(props) => (props.isDisabled ? '0.5' : '1')};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'all')};
`;

export type ActivityTableFooterProps = {
  tableType: TableType;
  onIsAllowed: (isAllowed: boolean) => void;
  isButtonDisabled?: boolean;
};
export const ActivityTableFooter: React.FC<ActivityTableFooterProps> = ({
  tableType,
  onIsAllowed,
  isButtonDisabled,
}) => {
  const showBlockButton =
    tableType === TableType.Allowed || tableType === TableType.Detected;
  const showAllowButton =
    tableType === TableType.Blocked || tableType === TableType.Detected;

  const handleIsAllowed = (isAllowed: boolean) => () => {
    onIsAllowed(isAllowed);
  };

  return (
    <ActivityFooter data-testid="first-child-activity-table-footer">
      {showBlockButton && (
        <DisableWrapper isDisabled={!!isButtonDisabled}>
          <Button data-testid="blockButton" onClick={handleIsAllowed(false)}>
            <Icon name="block" size="marginRight" />
            Block
          </Button>
        </DisableWrapper>
      )}

      {showAllowButton && (
        <DisableWrapper isDisabled={!!isButtonDisabled}>
          <Button data-testid="allowButton" onClick={handleIsAllowed(true)}>
            <Icon name="checkShield" size="marginRight" />
            Allow
          </Button>
        </DisableWrapper>
      )}
    </ActivityFooter>
  );
};
