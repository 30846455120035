import { gql } from 'apollo-boost';

export const GET_USER_ENROLLMENT = gql`
  query GetUserEnrollment {
    getUserEnrollment {
      id
      externalId
      sub
      bundleId
      createdAt
      updatedAt
    }
  }
`;
