import { MessageType } from 'roarr';

import { LogWriter } from './LogWriter';

type Level = 'trace' | 'debug' | 'info' | 'warn' | 'error';

class ConsoleLogWriter implements LogWriter {
  public isEnabled(): Promise<boolean> {
    return Promise.resolve(process.env.NODE_ENV !== 'production');
  }

  public async write(message: MessageType): Promise<void> {
    const level = this.getNormalizedLevel(message.context.logLevel);
    console[level](message);
  }

  private getNormalizedLevel(level?: number): Level {
    switch (level) {
      case 10:
        return 'trace';
      case 20:
        return 'debug';
      case 30:
        return 'info';
      case 40:
        return 'warn';
      case 50:
      case 60:
        return 'error';
      default:
        return 'info';
    }
  }
}
const consoleLogWriter = new ConsoleLogWriter();
export default consoleLogWriter;
