import { gql } from 'apollo-boost';

export const GET_USER_INTEGRATIONS = gql`
  query GetUserIntegrations {
    getUserIntegrations {
      id
      enrollmentId
      externalId
      sub
      vendor
      type
      authType
      setupComplete
      metadata {
        tenantId
        tenantIdKey
      }
      createdAt
      updatedAt
    }
  }
`;
