import React from 'react';
import { default as styled } from 'styled-components';
import {DeviceBox, DeviceType, SectionHeadline} from "../../../common";

const DeviceContainer = styled.div`
  margin: 0 -0.5rem;
  display: flex;
  flex-wrap: wrap;
`;

export type DeviceTypeSelectProps = {
  onDeviceSelect: (deviceSelected: string) => void;
};

const computerDownload = {
  deviceName: 'Computer',
  deviceType: DeviceType.Laptop,
};

const mobileDownload = {
  deviceName: 'Phone',
  deviceType: DeviceType.Mobile,
};

export const DeviceTypeSelect: React.FC<DeviceTypeSelectProps> = ({
  onDeviceSelect,
}) => (
  <div data-testid='device-type-select'>
    <SectionHeadline
      title="Protect a new device"
      subTitle="Select the type of device you want to protect:"
    />

    <DeviceContainer>
      <DeviceBox
        deviceDetails={computerDownload}
        onDeviceClick={() => onDeviceSelect('computer')}
      />
      <DeviceBox
        deviceDetails={mobileDownload}
        onDeviceClick={() => onDeviceSelect('phone')}
      />
    </DeviceContainer>
  </div>
);
