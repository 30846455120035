import React from 'react';
import { default as styled } from 'styled-components';
import { default as ExpertSetup } from './ExpertSetup';
import { TweekContext } from '../../../tweek';
import {Constrainer, SectionHeadline, SectionNavBar} from "../../../common";

const useTweekValue = TweekContext.useTweekValue;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NetworkSetup: React.FC = () => {
  const title = useTweekValue(
    'nashville/smb/security_bundle/setup/network_setup/title',
    'nashville/smb/security_bundle/setup/network_setup/sub_title',
  );
  const subTitle = useTweekValue(
    'nashville/smb/security_bundle/setup/network_setup/sub_title',
    'We use DNS-layer protection, which blocks unwanted domains, IP addresses, and cloud applications before they connect to your network.',
  );

  return (
    <Container data-testid='network-setup'>
      <SectionNavBar />
      <Constrainer>
        <SectionHeadline title={title} subTitle={subTitle} />
        <ExpertSetup />
      </Constrainer>
    </Container>
  );
};

export default NetworkSetup;
