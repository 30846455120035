import React from 'react';
import { css } from 'styled-components/macro';

function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

interface ProgressTrackerProps {
  totalSteps: number;
  currentStep: number;
}
const ProgressTracker: React.FC<ProgressTrackerProps> = ({
  totalSteps,
  currentStep,
}) => {
  const calculatedPercentage = (currentStep / totalSteps) * 100;

  // Clamp to between 0 and 100 percent
  const clampedPercentage = clamp(calculatedPercentage, 0, 100);

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
      data-testid="progress-tracker"
    >
      <p>
        {currentStep}/{totalSteps} Device setup complete
      </p>

      <div
        css={css`
          margin-left: 1rem;
          background-color: #c4c4c4;
          width: 170px;
          height: 10px;
          border-radius: 5px;
          position: relative;
          top: 2px;
        `}
      >
        <div
          css={css`
            background-color: #00ac3e;
            width: ${clampedPercentage}%;
            height: 10px;
            border-radius: 5px;
            transition: width 0.2s ease;
          `}
        />
      </div>
    </div>
  );
};

export default ProgressTracker;
