import { AnalyticsEventModel } from 'shisell';

import { getEnabledWriters } from './getEnabledWriters';
import { AnalyticsWriter } from '../writers/AnalyticsWriter';

let _writers: AnalyticsWriter[];

const getWriters = async () => {
  if (!_writers) {
    _writers = await getEnabledWriters();
  }
  return _writers;
};

export const combinedTrack = async (path: string) => {
  const writers = await getWriters();
  await Promise.all(writers.map((w) => w.track(path)));
};

export const combinedWrite = async (eventModel: AnalyticsEventModel) => {
  const writers = await getWriters();
  await Promise.all(writers.map((w) => w.write(eventModel)));
};
