import React from 'react';
import styled from 'styled-components';
import { Icon } from '../Icon';

const AddContainer = styled.div`
  width: 11rem;
  height: 9rem;
  background-color: var(--verizon-cool-gray1);
  border: 0.06em dashed var(--verizon-cool-gray6);
  box-sizing: border-box;
  border-radius: 0.4em;
  padding: 1rem;

  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;

  cursor: pointer;

  &:hover {
    background-color: var(--verizon-secondary-hover);
  }
`;

export type AddButtonProps = {
  message?: string;
  onClick?: () => void;
};

const AddButton: React.FC<AddButtonProps> = ({ message, onClick }) => (
  <AddContainer onClick={onClick} data-testid='first-child-add-button'>
    <Icon size="medium" name="addCircle1" />
    {message && <strong>{message}</strong>}
  </AddContainer>
);

export default AddButton;
