import * as React from 'react';
import styled from 'styled-components';

import { Spinner } from './Spinner';

const FullscreenContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 2;
`;

export const FullscreenSpinner: React.FC<{'data-testid'?: string}> = (props) => (
  <FullscreenContainer data-testid={props["data-testid"]}>
    <Spinner />
  </FullscreenContainer>
);
