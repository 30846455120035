import React, { useState } from 'react';
import { useTweekValue } from 'react-tweek';
import { Heading, Button } from '@soluto-private/atomic-ui-library-react';
import { Constrainer, Modal } from '../../../common';

export const UpgradeLicense: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const verizonSupportNumber = useTweekValue(
    'nashville/smb/security_bundle/support/verizonContact',
    '1 (800) 837-4966',
  );

  return (
    <>
      <Constrainer>
        <Heading size={5}>You&apos;ve registered all of your devices.</Heading>
        <Heading size={2}>To continue protecting a new device, remove an existing device or connect with an expert to add more licenses.</Heading>
        <Heading size={2}>It may take up to 48 hours for changes to be reflected to your account.</Heading>
        <Heading size={3}>Call to upgrade your seats.</Heading>
        <Button onClick={() => setShowModal(true)}>Call now</Button>
        <Modal heading={'Connect with Verizon'} onClose={() => setShowModal(false)} isOpen={showModal} ><a href={`tel:${verizonSupportNumber.replace(/\D/g, '')}`}>{verizonSupportNumber}</a></Modal>
      </Constrainer>
    </>
  )
}