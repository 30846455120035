import React from 'react';
import { Button, Heading } from '@soluto-private/atomic-ui-library-react';
import { ClickToCall } from '../../../common/components/Link';
import 'styled-components/macro';

const RemoveError: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <>
      <Heading size={3}>We are unable to remove this device</Heading>
      <div
        css={`
          margin-bottom: 3rem;
        `}
      >
        Please try refreshing your page or call <ClickToCall /> for support.
      </div>
      <Button onClick={onClose}>Okay</Button>
    </>
  );
};

export default RemoveError;
