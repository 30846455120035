import * as React from 'react';

export const InfoIconSVG: React.FC = (props) => {
  return (
    <svg data-testid='info-icon-svg' width={85} height={85} {...props}>
      <title>{'background'}</title>
      <path fill="none" d="M-1-1h582v402H-1z" />
      <g>
        <title>{'Layer 1'}</title>
        <path
          fill="#fff"
          d="M42.5.003C19.028.003 0 19.031 0 42.503s19.028 42.5 42.5 42.5S85 65.976 85 42.503 65.972.003 42.5.003zm-.212 66.267s-1.972 1.311-3.32 1.305l-.191.087.003-.087a4.316 4.316 0 01-.855-.125l-.426-.105c-2.354-.584-3.6-2.918-3.014-5.271l3.277-13.211 1.479-5.967c1.376-5.54-4.363 1.178-5.54-1.374-.777-1.687 4.464-5.227 8.293-7.896 0 0 1.97-1.309 3.319-1.304.121-.056.192-.087.192-.087l-.005.087c.285.013.57.053.857.124l.426.106a4.39 4.39 0 013.204 5.318l-3.276 13.212-1.482 5.967c-1.374 5.54 4.27-1.204 5.446 1.351.777 1.685-4.559 5.201-8.387 7.87zm8.306-41.294a6.147 6.147 0 11-11.933-2.961 6.147 6.147 0 0111.933 2.961z"
        />
      </g>
    </svg>
  );
};
