import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '../Checkbox';

const TableRowContainer = styled.tr`
  border-bottom: 0.06em solid var(--secondary-border-color);
  box-sizing: border-box;
`;

const ToggleCell = styled.td`
  width: 15%;
  margin: 0.25rem 2rem;
  text-align: center;
`;

const TableCell = styled.td`
  padding: 1em 0;
`;

const ToggleCellHeader = styled.th`
  width: 15%;
  margin: 0.25em 0.5em;
`;

const TableCellHeader = styled.th`
  padding: 1em 0;
  text-align: left;
`;

export type TableRowProps = {
  threatName: string;
  isThreatChecked?: boolean;
  onThreatCheck?: (bool: boolean) => void;
  isHeadRow?: boolean;
  hideCheckbox?: boolean;
};

export const TableRow: React.FC<TableRowProps> = ({
  threatName,
  isThreatChecked = false,
  onThreatCheck,
  isHeadRow = false,
  hideCheckbox,
}) => {

  const handleThreatCheck = () => {
    if (onThreatCheck) {
      onThreatCheck(!isThreatChecked);
    }
  };

  return (
    <TableRowContainer data-testid='first-child-table-row'>
      {isHeadRow ? (
        <>
          <ToggleCellHeader />
          <TableCellHeader>
            <strong>{threatName}</strong>
          </TableCellHeader>
        </>
      ) : (
        <>
          <ToggleCell>
            {!hideCheckbox && (
              <Checkbox
              onChange={handleThreatCheck}
              checked={isThreatChecked}
            />
            )}
          </ToggleCell>
          <TableCell>{threatName}</TableCell>
        </>
      )}
    </TableRowContainer>
  );
};
