import { createTweekClient, TweekClient } from 'tweek-client';

import { getAuthenticationToken } from './getAuthenticationToken';

const client: TweekClient = createTweekClient({
  baseServiceUrl: process.env.REACT_APP_TWEEK_URL ? process.env.REACT_APP_TWEEK_URL : '',
  getAuthenticationToken,
  useLegacyEndpoint: true,
});
export const getTweekClient = () => client;
