import React from 'react';
import { default as styled } from 'styled-components';
import { Icon } from './Icon';
import { useAnalytic } from '../../../../analytics';
import breakpoints from '../../../helpers/breakpoints';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 7.25rem;
  height: 11.63rem;
  align-items: center;
  margin: 0 3.5rem 1rem;

  @media (max-width: ${breakpoints.lg}) {
    margin-left: 2rem;
    margin-right: 2rem;
  }
`;

const IconContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);
  border-radius: 50%;
  width: 7.25rem;
  height: 7.25rem;
  outline: none;
  border: 0;

  :hover {
    background-color: var(--button-background-color--secondary-ghost--hover);
    border: 0.125em solid var(--primary-border-color);
    cursor: pointer;
  }
`;

const IconText = styled.p`
  font-family: var(--heading-font-family);
  margin: 0.6rem 0;
  width: 5.875rem;
  text-align: center;
  color: var(--primary-color);
`;

export interface RoundedIconProps {
  iconName: string;
  text?: string;
  onClick?: () => void;
}

export const RoundedIcon: React.FC<RoundedIconProps> = ({
  iconName,
  text,
  onClick,
}) => {
  const analytics = useAnalytic();
  const onClickWithTracking = () => {
    return () => {
      analytics.dispatcher
        .createScoped(`${iconName}`)
        .dispatch(`${iconName} Clicked`);
      return onClick && onClick();
    };
  };
  return (
    <Container data-testid='rounded-icon'>
      <IconContainer onClick={onClickWithTracking()} >
        <Icon name={iconName} size="large" />
      </IconContainer>
      <IconText>
        <strong>{text}</strong>
      </IconText>
    </Container>
  );
};
