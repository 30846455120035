import React from 'react';
import { default as styled } from 'styled-components/macro';
import { Link } from '@soluto-private/atomic-ui-library-react';
import {client} from "../../../authentication";
import {Icon} from "../../../common";

const LoggedInContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoggedIn: React.FC = () => {
  if (!client.isUserSignedIn()) {
    return null;
  }

  const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    client.signOut();
  };

  return (
    <LoggedInContainer>
      <Icon name="profileAvatar" size="medium" />
      <Link onClick={logout} style={{ padding: '0 0 0 1rem' }}>
        logout
      </Link>
    </LoggedInContainer>
  );
};
