import React from 'react';
import { default as styled } from 'styled-components';
import { Branding } from './Branding';
import { LoggedIn } from './LoggedIn';
import { Constrainer } from '../../../common/components/Layout';

const OuterContainer = styled.div`
  width: 100%;
  border-bottom: 0.1em solid var(--verizon-cool-gray6);
`;

const InnerContainer = styled.div`
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Navbar: React.FC = () => {
  return (
    <OuterContainer data-testid={'navbar'}>
      <Constrainer removeYPadding={true}>
        <InnerContainer>
          <Branding />
          <LoggedIn />
        </InnerContainer>
      </Constrainer>
    </OuterContainer>
  );
};
