import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html, body, #root {
    display: flex;
    margin: 0;
    width: 100%;
    height: 100%;
  }
`;
