import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import {
  LayoutContentContainer,
  InfoBox,
  ClickToCall,
} from '../../../common';
import { SetupNetworkView } from '../SetupNetworkView';
import { AdvancedNetworkSettingsView } from '../AdvancedNetworkSettingsView';
import { NetworkData, Network } from '../../models';
import { GET_NETWORKS } from '../../graphql';
import { Heading } from '@soluto-private/atomic-ui-library-react';
import { useTweekValue } from 'react-tweek';
import { useAnalytic } from '../../../analytics';

export const NetworkOverviewContainer: React.FC = () => {
  const analytics = useAnalytic();
  const { data, error: getNetworksError, loading: networksLoading } = useQuery<NetworkData>(GET_NETWORKS);
  const networks: Network[] = data?.getNetworks || [];

  const heading = useTweekValue(
    'nashville/smb/security_bundle/dashboard/network/heading',
    'Internet security',
  );

  if (getNetworksError) {
    analytics.dispatcher
      .createScoped('General Error')
      .dispatch('Error retrieving networks data');
  }

  const networksLoadedLayout = () => {
    return (
      <LayoutContentContainer>
        <Heading size={5}>{heading}</Heading>

        {getNetworksError ? (
          <InfoBox isError={true}>
            Looks like we were unable to connect your internet security settings.
            Check your router or call us for help <ClickToCall />
          </InfoBox>
        ) : (
            <>
              {networks.length === 0 ? (
                <SetupNetworkView />
              ) : (
                  <AdvancedNetworkSettingsView />
                )}
            </>
          )}
      </LayoutContentContainer>
    );
  };

  return (networksLoading ? <></> : networksLoadedLayout());
};
