import React, { useState, useContext, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { default as styled } from 'styled-components/macro';
import { Button, Heading } from '@soluto-private/atomic-ui-library-react';
import { DeviceList } from './DeviceList';
import {
  DeviceType,
  LayoutContentContainer,
  SectionHeadline,
  DeviceDetails,
  AppErrorContext,
  InfoBox,
  ClickToCall,
} from '../../../common';

import { DeviceData, GET_DEVICES, Device, REMOVE_DEVICE } from '../../../device';
import { useAnalytic } from '../../../analytics';

const DeviceListHeading = styled.div`
  max-width: 90em;
  overflow: auto;
  margin: 0 0 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const getDeviceTypeByOs = (os: string) => {
  const lowerCaseOs = os.toLowerCase();

  if (lowerCaseOs.includes('windows')) {
    return DeviceType.Windows;
  }

  if (lowerCaseOs.includes('apple') || lowerCaseOs.includes('macos')) {
    return DeviceType.Apple;
  }

  if (lowerCaseOs.includes('mobile') || lowerCaseOs.includes('ios')) {
    return DeviceType.Mobile;
  }

  return;
};

const getDeviceTypeByDeviceType = (deviceType: string) => {
  const lowerCaseDeviceType = deviceType.toLowerCase();

  if (lowerCaseDeviceType.includes('mobile')) {
    return DeviceType.Mobile;
  }

  return DeviceType.Laptop;
};

export const DeviceOverviewContainer: React.FC<{ bundleSize: number }> = ({
  bundleSize,
}) => {
  const analytics = useAnalytic();
  const [toggleRemoveMode, setToggleRemoveMode] = useState(false);
  const { setGeneralError } = useContext(AppErrorContext);
  const [removeDevice, { error: removeDeviceError }] = useMutation(
    REMOVE_DEVICE,
  );
  const { loading, data, error: getDevicesError } = useQuery<DeviceData>(
    GET_DEVICES,
  );

  useEffect(() => {
    if (getDevicesError) {
      setGeneralError();
      analytics.dispatcher
        .createScoped('General Error')
        .dispatch('Error retrieving device information');
    }

    if (removeDeviceError) {
      setGeneralError();
    }
  });

  const devices = data?.getDevices || [];

  const mappedDevices: DeviceDetails[] = devices.map((device) => ({
    deviceId: device.id,
    deviceName: device.name,
    deviceType:
      getDeviceTypeByOs(device.os) ||
      getDeviceTypeByDeviceType(device.deviceType),
    safeStatus: device.status,
  }));

  const removeClickHandler = () => {
    setToggleRemoveMode(!toggleRemoveMode);
  };

  const deviceRemoveHandler = (deviceToRemove: DeviceDetails): Promise<unknown> => {
    const realDevice = devices.find((d) => d.id === deviceToRemove.deviceId);

    if (realDevice === undefined) return Promise.reject();

    return removeDevice({
      variables: {
        deviceId: deviceToRemove.deviceId,
        deviceType: realDevice?.deviceType || deviceToRemove.deviceType,
      },
      update: (cache) => {
        const existingDevices = cache.readQuery<DeviceData>({
          query: GET_DEVICES,
        });

        const newDevices =
          existingDevices &&
          existingDevices.getDevices.filter((d: Device) => {
            return d.id !== deviceToRemove.deviceId;
          });

        cache.writeQuery({
          query: GET_DEVICES,
          data: { getDevices: newDevices },
        });
      },
    }).catch((err) => {
      setGeneralError();
      analytics.dispatcher
        .createScoped('General Error')
        .dispatch(err);
      throw err;
    });
  };
  const devicesRemaining = bundleSize - devices.length;

  return (
    <LayoutContentContainer data-testid='first-child-device-overview-container'>
      <SectionHeadline
        title="Device security"
        subTitle={
          getDevicesError
            ? ''
            : 'Your system is proactively identifying and defending against malware and malicious attacks.'
        }
      />

      {getDevicesError ? (
        <div
          css={`
            margin-top: 2rem;
          `}
        >
          <InfoBox isError={true}>
            Looks like we were unable to connect your devices. Try refreshing
            this page or call us for help <ClickToCall />
          </InfoBox>
        </div>
      ) : (
        <>
          <DeviceListHeading>
            <div
              css={`
                margin-right: 2rem;
              `}
            >
              <Heading size={3}>Choose a device to view activity.</Heading>
            </div>

            {!!mappedDevices.length && (
              <Button onClick={removeClickHandler}>
                {toggleRemoveMode ? 'Cancel' : 'Remove devices'}
              </Button>
            )}
          </DeviceListHeading>

          <DeviceList
            isLoading={loading}
            devices={mappedDevices}
            isRemoveMode={toggleRemoveMode}
            devicesRemaining={devicesRemaining}
            deviceRemoveHandler={deviceRemoveHandler}
          />

          <div
            css={`
              margin-top: 4px;
              font-weight: bold;
            `}
          >
            Devices available:{' '}
            <span
              css={`
                font-weight: normal;
              `}
            >
              {loading ? (
                <span
                  css={`
                    font-style: italic;
                    color: var(--tertiary-font-color);
                  `}
                >
                  loading...
                </span>
              ) : (
                devicesRemaining
              )}
            </span>
          </div>
        </>
      )}
    </LayoutContentContainer>
  );
};
