import React from 'react';
import { Heading } from '@soluto-private/atomic-ui-library-react';
import styled from 'styled-components';
import { ActionIconGroup } from './ActionIconGroup';
import { CardPrompt, Constrainer } from '../../../common';
import expert from './assets/expert.png';
import { TweekContext } from '../../../tweek';
import breakpoints from '../../../common/helpers/breakpoints';

const useTweekValue = TweekContext.useTweekValue;

const Container = styled.div`
  background-color: var(--verizon-cool-gray1);

  @media (max-width: 768px) {
  }
`;

const TopContainer = styled.div`
  margin: 3rem 0;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (max-width: ${breakpoints.md}) {
    flex-wrap: wrap;
    margin: 2rem 0;
  }

  @media (max-width: ${breakpoints.sm}) {
    margin: 1rem 0;
  }
`;

export const DashboardHelp: React.FC = () => {
  const heading = useTweekValue(
    'nashville/smb/security_bundle/dashboard/heading',
    'Need help?',
  );

  const cardPrompt = useTweekValue(
    'nashville/smb/security_bundle/dashboard/card_prompt',
    {
      title: 'How can we help?',
      subTitle: '24/7 Support',
      headerImgAlt: 'Support Image',
      options: [
        'Something isn’t working the way I expect it to.',
        'I need help installing device security to my phone.',
        'I want to learn more about my tech.',
      ],
    },
  );

  return (
    <Container data-testid='first-child-dashboard-help'>
      <Constrainer>
        <Heading size={7}>{heading}</Heading>

        <TopContainer>
          <ActionIconGroup />

          <CardPrompt
            title={cardPrompt.title}
            subTitle={cardPrompt.subTitle}
            headerImgSrc={expert}
            headerImgAlt={cardPrompt.headerImgAlt}
            options={cardPrompt.options}
          />
        </TopContainer>
      </Constrainer>
    </Container>
  );
};

export default DashboardHelp;
