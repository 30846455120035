import { gql } from 'apollo-boost';

export const SEND_SMS = gql`
  mutation sendSMS(
    $phoneNumber: String!
    $templateVars: TemplateVars
    $templateName: String
    $shortenUrl: Boolean!
    $partner: String!
  ) {
    sendSMS(
      input: {
        phoneNumber: $phoneNumber
        templateVars: $templateVars
        templateName: $templateName
        shortenUrl: $shortenUrl
        partner: $partner
      }
    ) {
      sent
    }
  }
`;
