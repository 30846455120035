import React, {ReactElement} from 'react';
import { Button, Heading } from '@soluto-private/atomic-ui-library-react';
import { css } from 'styled-components/macro';

interface ConfirmRemoveProps {
    children?: ReactElement | string | undefined,
  onCancel: () => void;
  onConfirm: () => void;
}
const ConfirmRemove: React.FC<ConfirmRemoveProps> = ({
  children,
  onCancel,
  onConfirm,
}) => {
  return (
    <>
      <Heading size={4}>
        Are you sure you want to stop protecting this device?
      </Heading>

      <div>{children}</div>

      <div
        css={css`
          margin-top: 3rem;
          display: flex;
        `}
      >
        <Button onClick={onConfirm}>Remove</Button>

        <div
          css={css`
            margin-left: 1rem;
          `}
        >
          <Button class="primary-ghost" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
};

export default ConfirmRemove;
