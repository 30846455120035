import styled from 'styled-components';
import breakpoints from '../../helpers/breakpoints';

const Constrainer = styled.div<{ maxWidth?: string; removeYPadding?: boolean }>`
  max-width: ${(props) => props.maxWidth || breakpoints.maxWidth};
  margin: 0 auto;
  padding: ${(props) => (props.removeYPadding ? '0 4rem' : '2rem 4rem')};

  @media (max-width: ${breakpoints.md}) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (max-width: ${breakpoints.sm}) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export default Constrainer;
