import React from 'react';
import 'styled-components/macro';
import { Heading } from '@soluto-private/atomic-ui-library-react';
import { Icon } from '../Icon/components';
import { default as styled } from 'styled-components';

const IconContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);
  outline: none;
  border: 0;
  cursor: pointer;
  margin: 0;
`;

interface SingleQuestionAndAnswerProps {
  question: string;
  answer: string[];
  isOpen: boolean;
  onToggle: () => void;
}
const SingleQuestionAndAnswer: React.FC<SingleQuestionAndAnswerProps> = ({
  question,
  answer,
  isOpen,
  onToggle,
}) => {
  return (
    <div
      css={`
        border-bottom: solid 1px var(--tertiary-font-color);
        padding: 1rem 0;
      `}
    >
      <div
        css={`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <Heading size={3}>{question}</Heading>
        <span
          css={`
            margin-left: 2rem;
          `}
        >
          <IconContainer
            data-testid={`questionToggle - ${question}`}
            onClick={onToggle}
          >
            <Icon name={isOpen ? 'minus' : 'plus'} />
          </IconContainer>
        </span>
      </div>

      {isOpen && (
        <div>
          {answer.map((a) => (
            <p
              key={a}
              css={`
                line-height: 150%;
              `}
            >
              {a}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default SingleQuestionAndAnswer;
