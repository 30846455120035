import { AnalyticsEventModel } from 'shisell';

import { AnalyticsWriter } from './AnalyticsWriter';

class ConsoleAnalyticsWriter implements AnalyticsWriter {
  public isEnabled(): Promise<boolean> {
    return Promise.resolve(process.env.NODE_ENV !== 'production');
  }

  public async write(eventModel: AnalyticsEventModel): Promise<void> {
    console.log('Analytic sent: ', eventModel);
  }

  public async track(path: string): Promise<void> {
    console.log('Tracking page: ', path);
  }
}

export default new ConsoleAnalyticsWriter();
