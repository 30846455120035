export const removeNonNumericCharacters = (str: string): string => {
  return str.replace(/\D/g, '');
};

/**
 * Formats an input field value to a generic US phone format
 * @param str input field value to format -- should be all numbers and 10 digits long
 */
export const formatPhoneNumber = (str: string): string => {
  if (str.length !== 10) {
    return str;
  }
  return str.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
};
