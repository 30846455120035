import React from 'react';

import { Button } from '@soluto-private/atomic-ui-library-react';
import { InfoBox } from '../../../common';
import { useTweekValue } from 'react-tweek';
import { useHistory } from 'react-router-dom';
import { useAnalytics } from 'react-shisell';
import { NETWORK_SETUP_PATH } from '../../../navigation';

export const SetupNetworkView: React.FC = () => {
  const history = useHistory();
  const analytics = useAnalytics();

  const message = useTweekValue(
    'nashville/smb/security_bundle/dashboard/network/add_network/text',
    'Looks like you haven’t added your network yet. Add a network to begin blocking unwanted sites and malware.',
  );

  const activateButton = useTweekValue(
    'nashville/smb/security_bundle/dashboard/network/add_network/button',
    'Activate network',
  );

  const onActivationButtonClick = () => {
    history.push(NETWORK_SETUP_PATH);
    analytics.dispatcher
      .createScoped('Activate Network')
      .dispatch('Activate Network Clicked');
  };

  return (
    <div data-testid='first-child-setup-network-view'>
      <InfoBox>
        <p style={{ marginTop: 0 }}>{message}</p>
        <Button onClick={onActivationButtonClick}>{activateButton}</Button>
      </InfoBox>
    </div>
  );
};
