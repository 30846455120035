import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import {AppError, AppErrorContext, Icon} from "../../../common";
import { ERROR_PATH } from '../../../navigation';
import {useTweekValue} from "react-tweek";

const ENABLE_CLOSE_BUTTON = false;

const ErrorContainer = styled.div`
  background: var(--color-warning-state);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 2rem;
  z-index: 1;

  button {
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    background: transparent;
  }
`;

const MessageContainer = styled.div`
  color: white;
  height: auto;
  padding: 1rem;
  flex: 1 1 auto;
  text-align: center;
`;

export const ErrorBanner: React.FC = () => {
  const history = useHistory();
  const { error, clearError } = useContext(AppErrorContext);
  const supportNumber = useTweekValue(
    'nashville/smb/security_bundle/support/numberD',
    '(844) 776-1960',
  );

  if (error === AppError.Terminal) {
    history.push(ERROR_PATH);
  }

  if (error !== AppError.General) {
    return <div data-testid='error-banner' />; // This is important due to css grid spacing in App.tsx
  }

  return (
    <ErrorContainer >
      {/* Empty div for spacing */}
      {ENABLE_CLOSE_BUTTON && <div />}

      <MessageContainer>
        Looks like something went wrong. Please try again. If the error
        persists, give us a call at {supportNumber}.
      </MessageContainer>

      {ENABLE_CLOSE_BUTTON && (
        <button data-testid="error-close-button" onClick={clearError}>
          <Icon name="closeWhite" />
        </button>
      )}
    </ErrorContainer>
  );
};
