import { createContext } from 'react';

export enum AppError {
  'General',
  'Terminal',
}

export type AppErrorContext = {
  error?: AppError;
  setGeneralError: () => void;
  setTerminalError: () => void;
  clearError: () => void;
};

export const AppErrorContext = createContext<AppErrorContext>(
  (undefined as unknown) as AppErrorContext,
);
