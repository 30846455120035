import * as React from 'react';

export const ErrorIconSVG: React.FC = (props) => {
  return (
    <svg width={32} height={32} {...props} data-testid='error-icon-svg'>
      <title>{'background'}</title>
      <path fill="none" d="M-1-1h582v402H-1z" />
      <g>
        <title>{'Layer 1'}</title>
        <circle fill="#fff" r={16} cy={16} cx={16} />
        <path fill="#d72828" d="M14.5 25h3v-3h-3v3zm0-19v13h3V6h-3z" />
      </g>
    </svg>
  );
};
