import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import {FillableIcon, Icon} from "../Icon";
import {useAnalytic} from "../../../analytics";

const DeviceBoxContainer = styled.div`
  margin: 1rem 0.5rem;
  width: 11em;
  border: 0.06em solid var(--secondary-border-color);
  box-sizing: border-box;
  border-radius: 0.4em;

  display: flex;
  flex-direction: column;
`;

const MainContainer = styled.div`
  height: 9em;
  position: relative;
  padding: 0 1em;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &.clickable {
    cursor: pointer;

    &:hover {
      background-color: var(--verizon-secondary-hover);
    }
  }
`;

const StatusIconContainer = styled.div`
  top: 0.5em;
  right: 0.5em;
  position: absolute;
  height: 0.1em;
`;

const DeviceIconContainer = styled.div`
  margin: 0.25em;
`;

const DeviceName = styled.p`
  height: 2em;
  text-align: center;
  margin: 0.5em 0;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RemoveContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-background-color);
  border-bottom-right-radius: 0.4em;
  border-bottom-left-radius: 0.4em;

  &:hover {
    cursor: pointer;
    background-color: var(--verizon-primary-hover);
  }
`;

const RemoveText = styled.p`
  color: var(--secondary-font-color);
`;

export enum DeviceType {
  Apple = 'apple',
  Windows = 'windows',
  Mobile = 'mobile',
  Laptop = 'laptop',
}

export type DeviceDetails = {
  deviceId?: string;
  deviceName: string;
  deviceType?: DeviceType;
  safeStatus?: string;
};

export type DeviceBoxProps = {
  deviceDetails: DeviceDetails;
  isRemoveMode?: boolean;
  isClickable?: boolean;
  onDeviceClick?: () => void;
  onRemoveClick?: () => void;
};

const deviceIconMap: Record<DeviceType, string> = {
  [DeviceType.Apple]: 'appleLaptop',
  [DeviceType.Mobile]: 'tablet',
  [DeviceType.Windows]: 'windowsLaptop',
  [DeviceType.Laptop]: 'laptop',
};

export const DeviceBox: React.FC<DeviceBoxProps> = ({
  deviceDetails,
  isRemoveMode,
  isClickable = true,
  onDeviceClick,
  onRemoveClick,
}) => {
  const analytics = useAnalytic();
  const onDeviceClickWithTracking = () => {
    return () => {
      analytics.dispatcher
        .createScoped('Device Item')
        .dispatch(`Device Item Clicked`);
      return onDeviceClick && onDeviceClick();
    };
  };

  return (
    <DeviceBoxContainer data-testid="first-child-device-box">
      <MainContainer
        className={classnames({ clickable: isClickable })}
        onClick={onDeviceClickWithTracking()}
      >
        <StatusIconContainer>
          {deviceDetails.safeStatus === 'safe' && <Icon name="checkCircle2" />}
          {deviceDetails.safeStatus === 'danger' && (
            <Icon name="interfaceAlertCircle" />
          )}
        </StatusIconContainer>

        <DeviceIconContainer>
          <Icon size="auto" name={deviceIconMap[deviceDetails.deviceType!]} />
        </DeviceIconContainer>

        <DeviceName>
          <strong>{deviceDetails?.deviceName}</strong>
        </DeviceName>
      </MainContainer>

      {isRemoveMode && (
        <RemoveContainer onClick={onRemoveClick}>
          <RemoveText>
            <strong>Remove</strong>
          </RemoveText>

          <FillableIcon fill="var(--secondary-color)" name="chevronRight" />
        </RemoveContainer>
      )}
    </DeviceBoxContainer>
  );
};
