import * as React from 'react';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from './UserContext';
import {
  authenticate,
  getCurrentUser,
  handleAuthenticationRedirect,
  setUserhandler,
  shouldHandleAuthenticationRedirect,
} from '../cognito';
import { TerminalError } from '../../main/components/Error';
import {useAnalytic} from "../../analytics";
import {User} from "../models";
import {BASE_PATH} from "../../navigation";
import {logger} from "../../logging";
import {FullscreenSpinner} from "../../common";
import { CognitoAuthSession } from 'amazon-cognito-auth-js';

interface AuthenticationWrapperProps {
    children?: React.ReactNode;
}

export const AuthenticationWrapper: React.FC<AuthenticationWrapperProps> = (props) => {
  const analytics = useAnalytic();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [user, setUser] = useState<User | undefined>();

  useEffect(() => {
    const getUserOrAuthenticate = () => {
      setUserhandler({
        onSuccess: (session: CognitoAuthSession | undefined) => {
          setUser(getCurrentUser(session));
          setLoading(false);
          history.push(BASE_PATH);
          // Needed to replicate previous functionality after updating the router
          window.location.reload();
        },
        onFailure: (err) => {
          logger.error(JSON.stringify({ err }), err.message);
          setError(err);
          setLoading(false);
          history.push('/token-error');
        },
      });

      if (shouldHandleAuthenticationRedirect()) {
        handleAuthenticationRedirect();
      } else {
        const currentUser = getCurrentUser();

        if (currentUser === undefined) {
          authenticate();
        } else {
          setUser(currentUser);
          setLoading(false);
        }
      }
    };

    getUserOrAuthenticate();
  }, [history]);

  if (loading) {
    return <FullscreenSpinner data-testid='first-child-authentication-wrapper'/>;
  }

  if (error) {
    console.log(`User authentication error: ${error}`);
    analytics.dispatcher
      .createScoped('Terminal Error')
      .dispatch('Error authenticating user');
    return <TerminalError />;
  }

  if (user) {
    return (
      <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
    );
  }

  console.log('Rendering terminal error.');
  return <TerminalError />;
};
