import * as React from 'react';

export const WarningIconSVG: React.FC = (props) => {
  return (
    <svg width={1792} height={1792} {...props} data-testid='warning-icon-svg'>
      <title>{'background'}</title>
      <path fill="none" d="M-1-1h582v402H-1z" />
      <g>
        <title>{'Layer 1'}</title>
        <path
          fill="#fff"
          d="M1024 1375v-190q0-14-9.5-23.5T992 1152H800q-13 0-22.5 9.5T768 1185v190q0 14 9.5 23.5t22.5 9.5h192q13 0 22.5-9.5t9.5-23.5zm-2-374l18-459q0-12-10-19-13-11-24-11H786q-11 0-24 11-10 7-10 21l17 457q0 10 10 16.5t24 6.5h185q14 0 23.5-6.5t10.5-16.5zm-14-934l768 1408q35 63-2 126-17 29-46.5 46t-63.5 17H128q-34 0-63.5-17T18 1601q-37-63-2-126L784 67q17-31 47-49t65-18 65 18 47 49z"
        />
      </g>
    </svg>
  );
};
