import { gql } from 'apollo-boost';

export const GET_DEVICES = gql`
  query GetDevices {
    getDevices {
      id
      name
      os
      model
      deviceType
      status
      quarantinedThreats
      unresolvedThreats
    }
  }
`;
