import React from 'react';
import 'styled-components/macro';

interface StatsCardProps {
  title: string;
  badgeText: string;
  children?: React.ReactNode;
}
const StatsCard: React.FC<StatsCardProps> = ({
  title,
  badgeText,
  children,
  ...otherProps
}) => {
  return (
    <div
      {...otherProps}
      css={`
        position: relative;
        flex: 1 1 100px;
        margin: 0.5rem;
        background-color: var(--primary-color);
        color: var(--secondary-font-color);
        padding: 38px 46px;
      `}
    >
      <div
        css={`
          position: absolute;
          top: 1rem;
          right: 1rem;
          background-color: #f2f2f2;
          color: black;

          font-size: 12px;
          padding: 2px 6px;
          border-radius: 18px;
        `}
      >
        <span
          css={`
            opacity: 0.6;
          `}
        >
          {badgeText}
        </span>
      </div>

      <div
        css={`
          font-size: 60px;
          font-weight: bold;
        `}
      >
        {children}
      </div>

      <div
        css={`
          margin-top: 16px;
          font-size: 24px;
        `}
      >
        {title}
      </div>
    </div>
  );
};

export default StatsCard;
