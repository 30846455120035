import React from 'react';
import styled from 'styled-components';

const DeviceBoxContainer = styled.div`
  margin: 1rem 0.5rem;
  width: 11em;
  height: 144px;
  border: 0.06em solid var(--secondary-border-color);
  box-sizing: border-box;
  border-radius: 0.4em;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const SmallSpinner = styled.div`
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  left: -1px;

  //noinspection CssOverwrittenProperties
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--secondary-border-color) transparent transparent
      transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const FakeDeviceBox: React.FC = () => {
  return (
    <DeviceBoxContainer>
      <SmallSpinner>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </SmallSpinner>
    </DeviceBoxContainer>
  );
};

export default FakeDeviceBox;
