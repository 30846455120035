import React, {ReactElement} from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useAnalytic } from '../../../analytics';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: currentColor;
`;

export interface BasicLinkProps {
  children?: ReactElement;
  to: string;
}

export const BasicLink: React.FC<BasicLinkProps> = ({ to, children }) => {
  const analytics = useAnalytic();
  const onClickWithTracking = () => {
    return () => {
      analytics.dispatcher
        .createScoped(`Link to ${to}`)
        .dispatch(`Link to ${to} Click`);
    };
  };
  return (
    <StyledLink onClick={onClickWithTracking()} to={to} data-testid={'basic-link'}>
      {children}
    </StyledLink>
  );
};
