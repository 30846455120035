import React from 'react';
import { default as styled } from 'styled-components/macro';
import { Heading } from '@soluto-private/atomic-ui-library-react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 34rem;
  background-color: var(--secondary-background-color);
  color: var(--secondary-font-color);
  border-radius: 0.5rem;
`;

const TopContainer = styled.div`
  display: flex;
  align-items: center;

  > img {
    height: 5.3em;
    margin: 1em;
    width: auto;
  }
`;

const OptionButton = styled.button`
  background-color: var(--primary-background-color);
  margin: 1rem;
  padding: 1.375rem;
  font-size: 1.25rem;
  border: 0.06em solid var(--secondary-border-color);
  box-sizing: border-box;
  border-radius: 0.5em;
  outline: none;
  text-align: left;
  cursor: pointer;

  &:hover {
    border-color: var(--primary-border-color);
  }
`;

type CardPromptProps = {
  title: string;
  subTitle: string;
  headerImgSrc: string;
  headerImgAlt?: string;
  options: string[];
};

export const CardPrompt: React.FC<CardPromptProps> = ({
  title,
  subTitle,
  headerImgSrc,
  headerImgAlt = 'header image',
  options,
}) => {
  const handleOptionClick = (option: string) => () => {
    (window.AE_SDK as {triggerMessagingOverlay: (option: string)=>void}).triggerMessagingOverlay(option);
  };

  return (
    <Container data-testid='first-child-card-prompt'>
      <TopContainer>
        <img src={headerImgSrc} alt={headerImgAlt} />

        <div
          css={`
            padding-right: 1rem;
          `}
        >
          <Heading size={6}>{title}</Heading>

          <div
            css={`
              font-size: 24px;
            `}
          >
            {subTitle}
          </div>
        </div>
      </TopContainer>

      {options.map((option, i) => (
        <OptionButton
          key={`OptionButton-${i}`}
          onClick={handleOptionClick(option)}
        >
          {option}
        </OptionButton>
      ))}
    </Container>
  );
};
