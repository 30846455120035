import React, { useContext, useEffect, useState } from 'react';
import { default as styled } from 'styled-components/macro';
import { Button, Heading } from '@soluto-private/atomic-ui-library-react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  DeviceData,
  DeviceThreat,
  DeviceThreatsData,
  DeviceThreatsVariables,
  FileStatus,
  PerformDeviceActionData,
  PerformDeviceActionVariables,
} from '../../models';
import {
  GET_DEVICE_THREATS,
  GET_DEVICES,
  PERFORM_DEVICE_ACTION,
} from '../../graphql';
import { BASE_PATH } from '../../../navigation';
import {
  ActivityTable,
  AppErrorContext, ClickToCall,
  Constrainer,
  FormattedThreat,
  Modal,
  SectionNavBar,
  Spinner, TableType,
  ThreatStatusType
} from "../../../common";
import {useAnalytic} from "../../../analytics";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  --heading-margin: 0;
`;

const ViewingActivityHeading = styled.div`
  margin-right: 1rem;
  margin-bottom: 0.5rem;
`;

const DeviceNameHeading = styled.div`
  color: var(--tertiary-font-color);
`;

const MobileInfoContainer = styled.div`
  max-width: 500px;
`;

const TableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1rem -0.5rem;
`;

const Table = styled.div`
  flex: 1 0 100px;
  margin: 1rem 0.5rem;
  min-width: 300px;
`;

// Desktop States: Allowed, At Risk, In Danger, Quarantined
// Mobile States: Resolved, At Risk, In Danger
const resolveFileStatus = (fs: FileStatus): ThreatStatusType => {
  switch (fs) {
    case 'Allowed': {
      return ThreatStatusType.Allowed;
    }

    case 'Resolved':
    case 'Quarantined': {
      return ThreatStatusType.Blocked;
    }

    // At Risk, In Danger, and anything else
    default: {
      return ThreatStatusType.Detected;
    }
  }
};

const formatDeviceThreats = (
  deviceThreats?: DeviceThreat[],
): FormattedThreat[] => {
  return (deviceThreats || []).map((d) => {
    return {
      name: d.name,
      fileStatus: resolveFileStatus(d.fileStatus),
    };
  });
};

export const DeviceDetail: React.FC = () => {
  const analytics = useAnalytic();
  const { clearError } = useContext(AppErrorContext);
  const history = useHistory();
  const { id: deviceId } = useParams<{ id: string }>();

  const [showModal, setShowModal] = useState(false);

  // GET_DEVICES
  const {
    loading: areDevicesLoading,
    error: devicesError,
    data: devicesData,
  } = useQuery<DeviceData>(GET_DEVICES);
  const devices = devicesData?.getDevices || [];
  const device = devices.find((d) => d.id === deviceId);

  const isMobile = device?.deviceType === 'mobile';

  // GET_DEVICE_THREATS
  const {
    loading: areDeviceThreatsLoading,
    error: deviceThreatsError,
    data: deviceThreatsData,
  } = useQuery<DeviceThreatsData, DeviceThreatsVariables>(GET_DEVICE_THREATS, {
    variables: { deviceId },
  });

  // PERFORM_DEVICE_ACTION
  const [
    performDeviceAction,
    { loading: isPerformDeviceActionLoading, error: performDeviceActionError },
  ] = useMutation<PerformDeviceActionData, PerformDeviceActionVariables>(
    PERFORM_DEVICE_ACTION,
  );

  const isThreatStuffLoading =
    areDeviceThreatsLoading || isPerformDeviceActionLoading;

  // Don't throw a banner error for deviceThreatsError. That state is shown
  // below in the activity tables.
  useEffect(() => {
    if (devicesError || performDeviceActionError) {
      analytics.dispatcher
        .createScoped('General Error')
        .dispatch('Error retrieving devices or performing device actions');
    } else {
      clearError();
    }
  }, [devicesError, performDeviceActionError, clearError, analytics]);

  const deviceThreats = deviceThreatsData?.getDeviceThreats;

  const deviceName = device?.name;
  const formattedThreats = formatDeviceThreats(deviceThreats?.pageItems);

  const filterThreats = (
    threats: FormattedThreat[],
    statusType: ThreatStatusType,
  ) => {
    return threats.filter(
      (threat: FormattedThreat) => threat.fileStatus === statusType,
    );
  };

  const prevStep = () => {
    history.push(BASE_PATH);
  };

  const handleIsAllowed = async ({
    threatNames,
    areAllowed,
  }: {
    threatNames: string[];
    areAllowed: boolean;
  }) => {
    const threatStatusEvent = areAllowed ? 'allow' : 'quarantine';
    const fileStatus: FileStatus = areAllowed ? 'Allowed' : 'Quarantined';

    const promises = threatNames.map((threatName) => {
      const threat = deviceThreats?.pageItems.find(
        (item) => item.name === threatName,
      );

      return (
        threat &&
        performDeviceAction({
          variables: {
            input: { deviceId, threatStatusEvent, fileHashId: threat.sha256 },
          },
          update: (cache) => {
            const getDeviceThreatsRes = cache.readQuery<
              DeviceThreatsData,
              DeviceThreatsVariables
            >({
              query: GET_DEVICE_THREATS,
              variables: { deviceId },
            });

            const existingDeviceThreats = getDeviceThreatsRes?.getDeviceThreats;

            if (existingDeviceThreats == null) return;

            const alreadyMovedItems = existingDeviceThreats.pageItems.filter(
              (item) => {
                return (
                  threatNames.includes(item.name) &&
                  item.fileStatus === fileStatus
                );
              },
            );

            // trying to ensure that we only do the following once no matter how many items are checked at once.
            if (alreadyMovedItems.length !== threatNames.length) {
              const newPageItems = existingDeviceThreats.pageItems.map(
                (item) => {
                  // Optimistically move all checked items at once
                  // but the end result is that all checked items will move to the appropriate list at once visually
                  // which is what the user expects
                  const checkedThreat = threatNames.find(
                    (name: string) => item.name === name,
                  );
                  return checkedThreat && item.fileStatus !== fileStatus
                    ? {
                        ...item,
                        fileStatus: fileStatus,
                      }
                    : item;
                },
              );

              const newDeviceThreats = {
                ...existingDeviceThreats,
                pageItems: newPageItems,
              };

              cache.writeQuery({
                query: GET_DEVICE_THREATS,
                data: { getDeviceThreats: newDeviceThreats },
                variables: { deviceId },
              });
            }
          },
        })
      );
    });

    try {
      await Promise.all(promises);
    } catch (error) {
      // No need to setGeneralError here. It is set above.
      setShowModal(true);
      analytics.dispatcher
        .createScoped('General Error')
        .dispatch(JSON.stringify(error));
    }
  };

  const detectedThreats = filterThreats(
    formattedThreats,
    ThreatStatusType.Detected,
  );

  const blockedThreats = filterThreats(
    formattedThreats,
    ThreatStatusType.Blocked,
  );

  const allowedThreats = filterThreats(
    formattedThreats,
    ThreatStatusType.Allowed,
  );

  return (
    <div data-testid='first-child-device-detail'>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        heading="We were unable to change threat status"
      >
        <div
          css={`
            margin-bottom: 3rem;
          `}
        >
          Please try refreshing your page or call <ClickToCall /> for support.
        </div>
        <Button onClick={() => setShowModal(false)}>Okay</Button>
      </Modal>
      <Container>
        <SectionNavBar onBackClick={prevStep} />

        <Constrainer>
          {areDevicesLoading ? (
            <Spinner />
          ) : (
            <>
              <Header>
                <ViewingActivityHeading>
                  <Heading size={5}>Viewing activity on:</Heading>
                </ViewingActivityHeading>

                <DeviceNameHeading>
                  <Heading size={5}>{deviceName}</Heading>
                </DeviceNameHeading>
              </Header>

              {isMobile && (
                <MobileInfoContainer>
                  <Heading size={3}>
                    To manage threats on this device, please use the Cylance
                    Mobile Security app.
                  </Heading>
                </MobileInfoContainer>
              )}

              <TableContainer>
                <Table data-testid="potentialThreatsTable">
                  <ActivityTable
                    isError={!!deviceThreatsError}
                    isLoading={isThreatStuffLoading}
                    tableType={TableType.Detected}
                    threats={detectedThreats}
                    currentActivityNumber={detectedThreats.length}
                    headingMessage="Potential threats"
                    onIsAllowed={handleIsAllowed}
                    hideActions={isMobile}
                  />
                </Table>

                <Table data-testid="blockedTable">
                  <ActivityTable
                    isError={!!deviceThreatsError}
                    isLoading={isThreatStuffLoading}
                    tableType={TableType.Blocked}
                    threats={blockedThreats}
                    currentActivityNumber={blockedThreats.length}
                    headingMessage={
                      isMobile ? 'Resolved threats' : 'Threats blocked'
                    }
                    onIsAllowed={handleIsAllowed}
                    hideActions={isMobile}
                  />
                </Table>

                {!isMobile && (
                  <Table data-testid="whitelistTable">
                    <ActivityTable
                      isError={!!deviceThreatsError}
                      isLoading={isThreatStuffLoading}
                      tableType={TableType.Allowed}
                      threats={allowedThreats}
                      currentActivityNumber={allowedThreats.length}
                      headingMessage="Whitelist"
                      onIsAllowed={handleIsAllowed}
                      hideActions={isMobile}
                    />
                  </Table>
                )}
              </TableContainer>
            </>
          )}
        </Constrainer>
      </Container>
    </div>
  );
};
