import React, { useState } from 'react';
import { default as styled } from 'styled-components/macro';
import { Button, Heading } from '@soluto-private/atomic-ui-library-react';
import SingleQuestionAndAnswer from './SingleQuestionAndAnswer';

const DeviceListHeading = styled.div`
  overflow: auto;
  margin: 2rem 0 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface QuestionAndAnswer {
  question: string;
  answer: string[];
}

interface QuestionsAndAnswersProps {
  title: string;
  questionsAndAnswers: QuestionAndAnswer[];
}

const QuestionsAndAnswers: React.FC<QuestionsAndAnswersProps> = ({
  title,
  questionsAndAnswers,
}) => {
  const [expandedQuestions, setExpandedQuestions] = useState<
    QuestionAndAnswer[]
  >([]);

  const areAllExpanded =
    expandedQuestions.length === questionsAndAnswers.length;

  function handleToggleExpandAll() {
    areAllExpanded
      ? setExpandedQuestions([])
      : setExpandedQuestions(questionsAndAnswers);
  }

  const handleToggle = (qAndA: QuestionAndAnswer) => () => {
    if (expandedQuestions.some((qa) => qa.question === qAndA.question)) {
      setExpandedQuestions(
        expandedQuestions.filter((qa) => qa.question !== qAndA.question),
      );
    } else {
      setExpandedQuestions([...expandedQuestions, qAndA]);
    }
  };

  return (
    <div>
      <DeviceListHeading>
        <div
          css={`
            --heading-margin: 0;
            margin-right: 2rem;
          `}
        >
          <Heading size={5}>{title}</Heading>
        </div>

        <Button onClick={handleToggleExpandAll}>
          {areAllExpanded ? 'Close all' : 'Expand all'}
        </Button>
      </DeviceListHeading>

      {questionsAndAnswers.map((qa) => (
        <SingleQuestionAndAnswer
          key={qa.question}
          question={qa.question}
          answer={qa.answer}
          isOpen={expandedQuestions.some((eq) => eq.question === qa.question)}
          onToggle={handleToggle(qa)}
        />
      ))}
    </div>
  );
};

export default QuestionsAndAnswers;
