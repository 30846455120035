import React from 'react';

import { App } from './main';
import * as serviceWorker from './serviceWorker';

import './main/initializations/dependencies';
import './index.css';
import config from './config';
import {createRoot} from "react-dom/client";

// Anywhere Expert SDK for chat feature
const aeAppKey = config.anywhereExpert.appKey;
if (aeAppKey === undefined) {
  throw new Error('Anywhere Expert app key is undefined');
}
(window.AE_SDK as {initialize: (aeAppKey: string)=>void}).initialize(aeAppKey);

const root = createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
);





// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
