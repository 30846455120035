import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  GET_USER_ENROLLMENT,
  GET_USER_INTEGRATIONS,
  UserEnrollmentData,
  UserIntegrationsData,
} from '../../../enrollment';
import { Heading } from '@soluto-private/atomic-ui-library-react';

import { AppErrorContext, FullscreenSpinner, Constrainer } from '../../../common';
import { DeviceOverviewContainer } from '../../../device';
import { NetworkOverviewContainer } from '../../../network';
import { StatsContainer } from '../Stats';
import DashboardHelp from './DashboardHelp';
import { useAnalytic } from '../../../analytics';

export const Dashboard: React.FC = () => {
  const analytics = useAnalytic();
  const { setTerminalError } = useContext(AppErrorContext);
  const userEnrollmentRes = useQuery<UserEnrollmentData>(GET_USER_ENROLLMENT);
  const userIntegrationsRes = useQuery<UserIntegrationsData>(
    GET_USER_INTEGRATIONS,
  );

  useEffect(() => {
    if (userEnrollmentRes.error || userIntegrationsRes.error) {
      console.log(`User enrollment error: ${userEnrollmentRes.error}`);
      console.log(`User integration error: ${userIntegrationsRes.error}`);
      setTerminalError();
      analytics.dispatcher
        .createScoped('Terminal Error')
        .dispatch('Error retrieving enrollment or integration data');
    }
  });

  const bundleMap: Record<string, number> = {
    BDLB1023: 5,
    BDLB1021: 10,
    BDLB1022: 25,
  };
  const isLoading = userEnrollmentRes.loading || userIntegrationsRes.loading;

  const userBundleId = userEnrollmentRes.data?.getUserEnrollment.bundleId;

  return (
    <div data-testid='dashboard'>
      {isLoading ? (
        <FullscreenSpinner />
      ) : (
          <>
            <Constrainer>
              <Heading size={7}>Business Internet Secure</Heading>

              <StatsContainer />

              <NetworkOverviewContainer />

              <DeviceOverviewContainer
                bundleSize={bundleMap[userBundleId || '']}
              />
            </Constrainer>

            <DashboardHelp />
          </>
        )}
    </div>
  );
};
