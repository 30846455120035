import { CognitoAuth } from 'amazon-cognito-auth-js';

const config = {
  ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID!,
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
  AppWebDomain: process.env.REACT_APP_COGNITO_DOMAIN!,
  IdentityProvider: process.env.REACT_APP_COGNITO_IDENTITY_PROVIDER,
  TokenScopesArray: ['openid'],
  ResponseType: 'code',
  RedirectUriSignIn: `${window.location.origin}/`,
  RedirectUriSignOut: `${window.location.origin}/logout/`,
};

const cognitoAuth = new CognitoAuth(config);
cognitoAuth.useCodeGrantFlow();

export const client = cognitoAuth;
