import React from 'react';
import { default as styled } from 'styled-components';
import { Button } from '@soluto-private/atomic-ui-library-react';
import { BASE_PATH } from '../../../navigation';
import { useHistory } from 'react-router-dom';
import { FillableIcon } from '../Icon';
import breakpoints from '../../helpers/breakpoints';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 2rem 0;

  @media (max-width: ${breakpoints.md}) {
    margin: 1rem;
  }
`;

export type NavBarProps = {
  onBackClick?: () => void;
};

export const SectionNavBar: React.FC<NavBarProps> = ({ onBackClick }) => {
  const history = useHistory();

  const goBack = () => {
    history.push(BASE_PATH);
  };
  return (
    <Container data-testid='first-child-nav-bar'>
      <Button class="text-button" onClick={onBackClick || goBack}>
        <FillableIcon name="arrowLeft" size="marginRight" />
        Back
      </Button>
    </Container>
  );
};
