import React from 'react';
import { default as styled } from 'styled-components';
import { Heading } from '@soluto-private/atomic-ui-library-react';
import { LicenseField } from './LicenseField';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  --heading-margin: 0;
`;

const List = styled.ol`
  font-size: 24px;
  padding: 0 0 0 28px;
  line-height: 150%;
  max-width: 600px;
`;

export type DeviceTroubleInstallProps = {
  licenseKey: string;
  isMobile?: boolean;
};

export const DeviceTroubleInstall: React.FC<DeviceTroubleInstallProps> = ({
  licenseKey,
  isMobile,
}) => (
  <Container data-testid="device-trouble-install">
    <Heading size={5}>Trouble Installing?</Heading>

    <List>
      <li>Download the installer again.</li>
      <li>Run the installer and follow the instructions.</li>
      <li>Reach out to our experts if you need additional support.</li>
    </List>

    {!isMobile ? <LicenseField licenseKey={licenseKey} /> : <></>}
  </Container>
);
