import React from 'react';
import { default as styled } from 'styled-components';
import { DeviceTroubleInstall } from './DeviceTroubleInstall';
import {SectionHeadline} from "../../../common";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export type DeviceCompleteProps = {
  licenseKey: string;
  isMobile?: boolean;
};

export const DeviceComplete: React.FC<DeviceCompleteProps> = ({
  licenseKey,
  isMobile,
}) => (
  <Container data-testid='device-complete'>
    {isMobile ? (
      <SectionHeadline
        title="Check your texts!"
        subTitle="Follow the activation link that we just sent to you and you are all set!"
      />
    ) : (
      <SectionHeadline
        title="You're almost finished!"
        subTitle="Run the download on the device you want to protect and follow the instructions."
      />
    )}

    <DeviceTroubleInstall licenseKey={licenseKey} isMobile={isMobile} />
  </Container>
);
