import React, { useState, useContext, useEffect } from 'react';
import { default as styled } from 'styled-components';
import {
  Button,
  Label,
  Heading,
} from '@soluto-private/atomic-ui-library-react';
import { useQuery } from '@apollo/react-hooks';
import { IOSAppStoreSvg } from './IOSAppStoreSvg';
import { GooglePlayStoreSVG } from './GooglePlayStoreSVG';
import {
  AppErrorContext,
  ConfigContext,
  formatPhoneNumber,
  removeNonNumericCharacters,
  SectionHeadline, Toast
} from "../../../common";
import {GET_ACTIVATION_URL} from "../../../device";
import {useAnalytic} from "../../../analytics";

const StepContainer = styled.div`
  padding: 1em 0 2em;
  border-bottom: 1px solid var(--primary-color);
  font-family: var(--primary-font-family);
`;

const Field = styled.input`
  border: 0.06em solid var(--primary-color);
  box-shadow: inset 0 -0.5em 0 var(--primary-color);
  height: 3em;
  color: var(--primary-font-color);
  font-family: var(--primary-font-family);
  font-weight: bold;
  font-size: 1em;
  text-align: center;

  &.disabled {
    background-color: var(--verizon-cool-gray1);
    cursor: not-allowed;
  }
`;

const ButtonContainer = styled.div``;

const ErrorMessage = styled.p`
  font-size: 0.75em;
  font-family: var(--primary-font-family);
  color: var(--color-dark-red);
`;

const AppStoreSelectionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 60px;
`;

const AppStoreSection = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 1rem;

  svg {
    margin-left: 0.5rem;
  }

  input {
    cursor: pointer;
  }
`;

const DisableWrapper = styled.div<{ isDisabled: boolean }>`
  opacity: ${(props) => (props.isDisabled ? '0.5' : '1')};
`;

const AppLinkButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;

  & span {
    margin-left: 1rem;
  }
`;

export type MobileDeviceDownloadProps = {
  licenseKey: string;
  onSendTextClicked: (
    mdn: string,
    templateVars: { URL: string; PRODUCT_NAME?: string; APP_NAME?: string },
    templateName: string,
    advance: boolean,
  ) => void;
  sendSMSError?: boolean;
  sendSMSSuccess?: boolean;
};

export const MobileDeviceDownload: React.FC<MobileDeviceDownloadProps> = ({
  onSendTextClicked,
  sendSMSError,
  sendSMSSuccess,
}) => {
  const [mdn, setMdn] = useState('');
  const [appOs, setAppOs] = useState<'ios' | 'android' | undefined>();
  const [inputError, setInputError] = useState<string | undefined>(undefined);
  const [textWasSent, setTextWasSent] = useState(false);

  const { setGeneralError } = useContext(AppErrorContext);
  const {
    links: { downloads },
    sms,
  } = useContext(ConfigContext);

  const activationUrl = useQuery(GET_ACTIVATION_URL);

  const analytics = useAnalytic();

  useEffect(() => {
    if (activationUrl.error) {
      analytics.dispatcher
        .createScoped('Mobile Device Download')
        .dispatch('Activation URL Error');
      setGeneralError();
    }
  });

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target?.value;
    // If the current value passes the validity test then apply that to state
    if (val && val.length) {
      const cleaned = removeNonNumericCharacters(val);
      if (!cleaned || !cleaned.length) {
        setMdn('');
      } else {
        setMdn(formatPhoneNumber(cleaned));
      }
    } else if (val === '') {
      setMdn('');
    }
  };

  const clickHandler = () => {
    /** Clean the entered text so we can verify the length, and pass the digits only to the API. */
    const cleaned = removeNonNumericCharacters(mdn);

    if (!cleaned || cleaned.length !== 10) {
      setInputError('Phone number must be 10 digits long');
      return;
    }

    if (!appOs) {
      setInputError('Select an app store icon first');
      return;
    }

    if (!textWasSent) {
      setInputError('Click Send App Link button first');
      return;
    }

    analytics.dispatcher
      .createScoped('Mobile Device Download')
      .dispatch('Send Activation SMS Button Click');

    onSendTextClicked(
      cleaned,
      {
        URL: activationUrl?.data?.getActivationUrl?.url as string,
        APP_NAME: 'Cylance Mobile Security',
      },
      sms.activationTemplateName as string,
      true,
    );
  };

  const downloadClickHandler = () => {
    /** Clean the entered text so we can verify the length, and pass the digits only to the API. */

    const cleaned = removeNonNumericCharacters(mdn);

    if (!cleaned || cleaned.length !== 10) {
      setInputError('Phone number must be 10 digits long');
      return;
    }

    if (!appOs) {
      setInputError('Select an app store icon first');
      return;
    }

    analytics.dispatcher
      .createScoped('Mobile Device Download')
      .dispatch('Send Download SMS Button Click');

    setTextWasSent(true);

    onSendTextClicked(
      cleaned,
      {
        URL: downloads[appOs] as string,
        PRODUCT_NAME: 'Verizon Business Internet Secure',
      },
      sms.appDownloadTemplateName as string,
      false,
    );
  };

  const selectOs = (os: 'ios' | 'android') => () => {
    setAppOs(os);
  };

  const isValidMdn =
    mdn.length && removeNonNumericCharacters(mdn).length === 10;

  return (
    <>
      <SectionHeadline
        data-testid='mobile-device-download'
        title="Protect a new mobile device"
        subTitle="Follow the steps below to setup and activate a new mobile device."
      />

      <StepContainer>
        <Heading size={3}>
          1. Enter the device phone number in the box below
        </Heading>

        <Label>Mobile Number</Label>

        <Field
          className={inputError ? 'error' : ''}
          data-testid="mdn-input"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            changeHandler(event)
          }
          type="text"
          value={mdn}
          maxLength={12}
        />

        {inputError ? <ErrorMessage>{inputError}</ErrorMessage> : <></>}
      </StepContainer>

      <StepContainer>
        <Heading size={3}>2. Download the Cylance Mobile Security App</Heading>

        <Heading size={2} className="light">
          Choose your preferred app store then click the button to get a link.
        </Heading>

        <AppStoreSelectionContainer>
          <AppStoreSection style={{ marginRight: '.5em' }}>
            <input
              data-testid="ios-app-store"
              type="radio"
              name="selectedAppStore"
              onClick={selectOs('ios')}
            />
            <IOSAppStoreSvg width={160} height={50} />
          </AppStoreSection>

          <AppStoreSection style={{ marginLeft: '.5em' }}>
            <input
              data-testid="google-play-store"
              type="radio"
              name="selectedAppStore"
              onClick={selectOs('android')}
            />
            <GooglePlayStoreSVG width={150} height={47} />
          </AppStoreSection>
        </AppStoreSelectionContainer>

        <AppLinkButtonContainer>
          <DisableWrapper isDisabled={!isValidMdn || appOs === undefined}>
            <Button
              data-testid="activate-button"
              onClick={downloadClickHandler}
            >
              Send App Link
            </Button>
          </DisableWrapper>

          {textWasSent && !sendSMSSuccess && !sendSMSError && (
            <span>Sending text...</span>
          )}
        </AppLinkButtonContainer>
      </StepContainer>

      <StepContainer>
        <Heading size={3}>
          2. Activate the device on your Cylance account
        </Heading>

        <Heading size={2} className="light">
          After installing the app, click the button below to get your
          activation link.
        </Heading>

        <ButtonContainer>
          <DisableWrapper
            isDisabled={!sendSMSSuccess || !isValidMdn || appOs === undefined}
          >
            <Button data-testid="activate-button" onClick={clickHandler}>
              Send Activation Link
            </Button>
          </DisableWrapper>
        </ButtonContainer>
      </StepContainer>

      {sendSMSError ? (
        <Toast
          toastList={[
            {
              id: 0,
              type: 'danger',
              description: 'We encountered a problem sending this text message',
              title: 'SMS Error',
            },
          ]}
        />
      ) : (
          <></>
        )}

      {sendSMSSuccess ? (
        <Toast
          toastList={[
            {
              id: 0,
              type: 'success',
              description: `Text message sent to ${mdn}`,
              title: 'Success!',
            },
          ]}
        />
      ) : (
          <></>
        )}
    </>
  );
};
