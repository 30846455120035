import React from 'react';
import { default as styled } from 'styled-components';
import { Heading } from '@soluto-private/atomic-ui-library-react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  max-width: 500px;
  --heading-margin: 0.5rem 0;
`;

type HeadlineProps = {
  title: string;
  subTitle: string;
  'data-testid'?: string;
};

export const SectionHeadline: React.FC<HeadlineProps> = ({
  title,
  subTitle,
  'data-testid': dataTestId,
}) => (
  <Container data-testid={dataTestId}>
    <Heading size={5}>{title}</Heading>
    <Heading class="light" size={4}>
      {subTitle}
    </Heading>
  </Container>
);
