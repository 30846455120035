import React, {ReactElement} from 'react';
import styled from 'styled-components';
import { Heading } from '@soluto-private/atomic-ui-library-react';
import breakpoints from '../../helpers/breakpoints';
import {Icon} from "../Icon";

const Mask = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.33);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background: white;
  color: black;
  min-width: 30%;
  max-width: 60%;
  padding: 1rem 3rem 2rem 2rem;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.3);
  position: relative;

  @media (max-width: ${breakpoints.sm}) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem 3rem 2rem 2rem;
    box-shadow: none;
    width: unset;
    min-width: unset;
    max-width: unset;
  }
`;

const CloseContainer = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: transparent;
  border: none;
  cursor: pointer;

  :focus {
    outline: none;
  }
`;

const TextContainer = styled.div`
  margin: 1.5rem 0 0;
`;

export type ModalProps = {
  heading?: string;
  isOpen: boolean;
  onClose: () => void;
  children?: ReactElement[] | ReactElement | string;
};

export const Modal: React.FC<ModalProps> = ({
  heading,
  isOpen,
  onClose,
  children,
}) => (
  <div data-testid='first-child-modal'>
    {isOpen && (
      <Mask>
        <ModalContainer data-testid="modalContainer">
          <Heading size={3} data-testid='modal-heading'>{heading}</Heading>

          <CloseContainer
            data-testid={'modalCloseButton'}
            onClick={() => onClose()}
          >
            <Icon name="close" alt="close"/>
          </CloseContainer>

          <TextContainer >{children}</TextContainer>
        </ModalContainer>
      </Mask>
    )}
  </div>
);