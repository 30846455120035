import React from 'react';
import { default as styled } from 'styled-components';
import { Button } from '@soluto-private/atomic-ui-library-react';
import {DeviceBox, DeviceType, SectionHeadline} from "../../../common";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const DevicesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem;
`;

const SingleDeviceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0.5rem;
`;

const appleDownload = {
  deviceName: 'Apple',
  deviceType: DeviceType.Apple,
};

const windowsDownload = {
  deviceName: 'Windows',
  deviceType: DeviceType.Windows,
};

export type DeviceDownloadProps = {
  onDownloadTypeSelected: (deviceSelected: string) => void;
};
export const DeviceDownload: React.FC<DeviceDownloadProps> = ({
  onDownloadTypeSelected,
}) => (
  <Container data-testid='device-download'>
    <SectionHeadline
      title="Protect a new device"
      subTitle="What kind of computer do you have?"
    />

    <DevicesContainer>
      <SingleDeviceContainer>
        <DeviceBox isClickable={false} deviceDetails={appleDownload} />

        <Button onClick={() => onDownloadTypeSelected('osx')}>
          Download OSX
        </Button>
      </SingleDeviceContainer>

      <SingleDeviceContainer>
        <DeviceBox isClickable={false} deviceDetails={windowsDownload} />

        <Button onClick={() => onDownloadTypeSelected('windows')}>
          Download Windows
        </Button>
      </SingleDeviceContainer>
    </DevicesContainer>
  </Container>
);
