/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useContext, useEffect } from 'react';
import { default as styled } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { DeviceTypeSelect } from './DeviceTypeSelect';
import { DeviceDownload } from './DeviceDownload';
import { DeviceComplete } from './DeviceComplete';
import { DeviceTroubleInstall } from './DeviceTroubleInstall';
import { MobileDeviceDownload } from './MobileDeviceDownload';
import { Heading } from '@soluto-private/atomic-ui-library-react';
import ProgressTracker from './ProgressTracker';
import {useAnalytic} from "../../../analytics";
import {AppErrorContext, ConfigContext, Constrainer, SectionNavBar} from "../../../common";
import {GET_INSTALLATION_TOKEN, SEND_SMS} from "../../../device";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {BASE_PATH} from "../../../navigation";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  --heading-margin: 1rem 0;
`;

const SubHeading = styled.p`
  margin-top: 1rem;
  font-size: 24px;
  line-height: 135%;
  max-width: 600px;
`;

export const DeviceSetup: React.FC = () => {
  const analytics = useAnalytic();
  const history = useHistory();
  const {
    links: { downloads },
  } = useContext(ConfigContext);
  const { setGeneralError } = useContext(AppErrorContext);

  const [currentStep, setCurrentStep] = useState<Step>('DeviceSelect');
  const [prevSteps, setPrevSteps] = useState<Step[]>([]);
  const [sendSMSSuccess, setSendSMSSuccess] = useState(false);

  const installationToken = useQuery(GET_INSTALLATION_TOKEN);
  const [sendSMS, { error: sendSMSError }] = useMutation(SEND_SMS);

  useEffect(() => {
    if (installationToken.error) {
      setGeneralError();
      analytics.dispatcher
        .createScoped('General Error')
        .dispatch('Error retrieving installation token');
    }

    if (sendSMSError) {
      setGeneralError();
      analytics.dispatcher
        .createScoped('General Error')
        .dispatch('Error sending SMS');
    }
  });

  const licenseKey = installationToken?.data?.getInstallationToken?.token;

  const steps = {
    DeviceSelect: <DeviceTypeSelect onDeviceSelect={handleDeviceSelection} />,
    DeviceDownload: (
      <DeviceDownload onDownloadTypeSelected={handleDownloadClicked} />
    ),
    DeviceComplete: (
      <DeviceComplete licenseKey={licenseKey} isMobile={sendSMSSuccess} />
    ),
    MobileDeviceDownload: (
      <MobileDeviceDownload
        licenseKey={licenseKey}
        onSendTextClicked={handlePhoneNumberEntered}
        sendSMSError={!!sendSMSError}
        sendSMSSuccess={sendSMSSuccess}
      />
    ),
    DeviceTroubleInstall: (
      <DeviceTroubleInstall licenseKey={licenseKey} isMobile={sendSMSSuccess} />
    ),
  };

  type Step = keyof typeof steps;

  const totalProgressSteps = 3;
  const currentProgressStep = calculateCurrentProgressStep(currentStep);

  function calculateCurrentProgressStep(currentStep: Step): number {
    switch (currentStep) {
      case 'DeviceSelect': {
        return 1;
      }

      case 'DeviceDownload':
      case 'MobileDeviceDownload': {
        return 2;
      }

      case 'DeviceTroubleInstall':
      case 'DeviceComplete': {
        return 3;
      }
    }
  }

  function handleBackClick() {
    const lastStep = prevSteps.pop();

    if (lastStep) {
      setCurrentStep(lastStep);
      setPrevSteps(prevSteps);
    } else {
      history.push(BASE_PATH);
    }
  }

  function nextStep(next: Step) {
    setPrevSteps([...prevSteps, currentStep]);
    setCurrentStep(next);
  }

  function handleDeviceSelection(selectedDevice: string) {
    setPrevSteps([...prevSteps, currentStep]);
    if (selectedDevice === 'phone') {
      nextStep('MobileDeviceDownload');
    } else {
      nextStep('DeviceDownload');
    }
  }

  function handleDownloadClicked(os: string) {
    const newWindow = window.open(downloads[os], '_blank');

    if (newWindow) {
      setTimeout(() => nextStep('DeviceComplete'), 1000);
    }
  }

  function handlePhoneNumberEntered(
    mdn: string,
    templateVars: { URL: string; PRODUCT_NAME?: string; APP_NAME?: string },
    templateName: string,
    advance: boolean,
  ) {
    sendSMS({
      variables: {
        phoneNumber: mdn,
        templateName,
        templateVars,
        shortenUrl: true,
        partner: 'verizon',
      },

      update: () => {
        setSendSMSSuccess(true);

        if (advance) {
          nextStep('DeviceComplete');
        }
      },
    }).catch((error) => {
      setGeneralError();
      analytics.dispatcher
        .createScoped('General Error')
        .dispatch(error);
    });
  }

  return (
    <Container data-testid='device-setup'>
      <SectionNavBar onBackClick={handleBackClick} />

      <Constrainer>
        <ProgressTracker
          totalSteps={totalProgressSteps}
          currentStep={currentProgressStep}
        />

        <Heading size={6}>Business Internet Secure</Heading>

        <SubHeading>
          Your security service is proactively identifying and defending against
          malware and malicious attacks.
        </SubHeading>

        {steps[currentStep]}
      </Constrainer>
    </Container>
  );
};
