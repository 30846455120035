import { gql } from 'apollo-boost';

export const PERFORM_DEVICE_ACTION = gql`
  mutation performDeviceAction($input: PerformDeviceActionInput) {
    performDeviceAction(input: $input) {
      message
      errors
    }
  }
`;
