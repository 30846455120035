import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import 'styled-components/macro';
import {
  AppError,
  AppErrorContext, ClickToCall,
  Constrainer,
  InfoBox,
  LayoutContentContainer,
  SectionHeadline
} from "../../../common";

export const TerminalError: React.FC = () => {
  const { error } = useContext(AppErrorContext);

  if (error !== AppError.Terminal) {
    return <Redirect to="/" />;
  }

  return (
    <Constrainer>
      <LayoutContentContainer>
        <SectionHeadline title="Something went wrong" subTitle="" />

        <div
          css={`
            margin-top: 2rem;
          `}
        >
          <InfoBox>
            We were not able to process your request. Please try refreshing the
            page or call us for help <ClickToCall />
          </InfoBox>
        </div>
      </LayoutContentContainer>
    </Constrainer>
  );
};
