import React, { useState } from 'react';
import { default as styled } from 'styled-components/macro';
import { AddButton, DeviceBox, Modal } from '../../../common';
import { DEVICE_SETUP_PATH } from '../../../navigation';
import { useHistory } from 'react-router-dom';
import { DeviceDetails } from '../../../common';
import sortBy from 'lodash/sortBy';
import { UPGRADE_PATH } from '../../../navigation';
import ConfirmRemove from './ConfirmRemove';
import { analytics } from 'react-shisell';
import FakeDeviceBox from '../../../setup/components/DeviceSetup/FakeDeviceBox';
import RemoveError from './RemoveError';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5em;

  > div {
    margin: 0.5em;
  }
`;

function sortDevices(d: DeviceDetails[]): DeviceDetails[] {
  const safeDevices = sortBy(
    d.filter((d) => d.safeStatus === 'safe'),
    'deviceName',
  );
  const unsafeDevices = sortBy(
    d.filter((d) => d.safeStatus !== 'safe'),
    'deviceName',
  );

  return [...unsafeDevices, ...safeDevices];
}

export type DeviceListProps = {
  isRemoveMode: boolean;
  devices: DeviceDetails[];
  devicesRemaining: number;
  deviceRemoveHandler: (device: DeviceDetails) => Promise<unknown>;
  isLoading?: boolean;
};

export const DeviceList: React.FC<DeviceListProps> = ({
  devices,
  isRemoveMode,
  devicesRemaining,
  deviceRemoveHandler,
  isLoading,
}) => {
  const history = useHistory();
  const [deviceToRemove, setDeviceToRemove] = useState<
    DeviceDetails | undefined
  >();
  const [isRemoveError, setIsRemoveError] = useState(false);

  const sortedDevices = sortDevices(devices);

  const addButtonClicked = () => {
    history.push(DEVICE_SETUP_PATH);
  };
  const addLicensesClicked = () => {
    history.push(UPGRADE_PATH);
  };

  const deviceClicked = (device: DeviceDetails) => {
    history.push(`/device/${device.deviceId}`);
  };

  const onDeviceRemoveClick = (deviceToRemove: DeviceDetails) => {
    setDeviceToRemove(deviceToRemove);
  };

  const onRemoveConfirm = async () => {
    if (deviceToRemove) {
      analytics.dispatcher
        .createScoped('Remove Device Button')
        .dispatch('Remove Device Clicked');

      try {
        await deviceRemoveHandler(deviceToRemove);
        setDeviceToRemove(undefined);
      } catch {
        setIsRemoveError(true);
      }
    }
  };

  const handleModalClose = () => {
    setDeviceToRemove(undefined);
  };

  return (
    <div data-testid='first-child-device-list'>
      <Modal isOpen={!!deviceToRemove} onClose={handleModalClose}>
        {isRemoveError ? (
          <RemoveError onClose={handleModalClose} />
        ) : (
          <ConfirmRemove
            onCancel={handleModalClose}
            onConfirm={onRemoveConfirm}
          >
            {deviceToRemove?.deviceName}
          </ConfirmRemove>
        )}
      </Modal>

      <Container>
        {devicesRemaining > 0 ? (
          <AddButton message="Add Device" onClick={addButtonClicked} />
        ) : (
          <AddButton message="Add licenses" onClick={addLicensesClicked} />
        )}

        {isLoading ? (
          <FakeDeviceBox />
        ) : (
          sortedDevices.map((item, index) => (
            <DeviceBox
              data-testid="deviceBox"
              key={`Device-${index}`}
              deviceDetails={item}
              isRemoveMode={isRemoveMode}
              onDeviceClick={() => deviceClicked(item)}
              onRemoveClick={() => {
                onDeviceRemoveClick(item);
              }}
            />
          ))
        )}
      </Container>
    </div>
  );
};
