import {getCurrentUser} from "../../authentication";
import {setContext} from "apollo-link-context";
import {HttpLink} from "apollo-boost";
import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/react-hooks';

export const buildAuth = (
  headers: Record<string, string | number | boolean>,
) => {
  const { idToken } = getCurrentUser() || {};
  return {
    headers: {
      ...headers,
      authorization: idToken,
    },
  };
};
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_APPSYNC_ENDPOINT
});

const authLink = setContext((_, { headers }) => {
  return buildAuth(headers);
});

const link = authLink.concat(httpLink) as unknown as ApolloLink;
export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});
