import { createContext, useContext } from 'react';

import { User } from '../models';

export const initialUserContext = {
  sub: '',
  externalId: '',
  username: '',
  accessToken: '',
  idToken: '',
  refreshToken: '',
  expiration: '',
} as User;

export const UserContext = createContext(initialUserContext);
UserContext.displayName = 'UserContext';

export const useUser = (): User => useContext(UserContext);
