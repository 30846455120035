import React, { useContext, useEffect } from 'react';
import 'styled-components/macro';

import StatsCard from './StatsCard';
import { useQuery } from '@apollo/react-hooks';
import { DeviceData } from '../../../device';
import { GET_DEVICES } from '../../../device';
import { AppErrorContext } from '../../../common';
import { Spinner } from '../../../common';
import { GET_REQUESTS_BY_TIME_RANGE, RequestsByTimeRangeData } from '../../../network';
import { useAnalytic } from '../../../analytics';

const StatsContainer: React.FC = () => {
  const analytics = useAnalytic();
  const { setGeneralError } = useContext(AppErrorContext);

  const {
    loading: areDevicesLoading,
    error: devicesError,
    data: devicesData,
  } = useQuery<DeviceData>(GET_DEVICES);
  const devices = devicesData?.getDevices || [];

  const {
    loading: areRequestsLoading,
    error: requestsError,
    data: requestsData,
  } = useQuery<RequestsByTimeRangeData>(GET_REQUESTS_BY_TIME_RANGE);
  const blockedRequests = requestsData?.getRequestsByTimeRange || [];

  useEffect(() => {
    if (devicesError || requestsError) {
      setGeneralError();
      analytics.dispatcher
        .createScoped('General Error')
        .dispatch('Error retrieving stats for networks or devices');
    }
  });

  const { potentialThreats, threatsBlocked } = devices.reduce(
    (acc, curr) => {
      acc.potentialThreats += curr.unresolvedThreats;
      acc.threatsBlocked += curr.quarantinedThreats;

      return acc;
    },
    { potentialThreats: 0, threatsBlocked: 0 },
  );

  const { blockedRequestCount } = blockedRequests.reduce(
    (accumulator, currentRequestBlock) => {
      accumulator.blockedRequestCount += currentRequestBlock.countsByType.blocked;
      return accumulator;
    },
    { blockedRequestCount: 0 },
  );

  const isLoading = areDevicesLoading || areRequestsLoading;

  return (
    <div
      css={`
        display: flex;
        justify-content: space-between;
        margin: 0 -0.5rem;
        flex-wrap: wrap;
      `}
    >
      {isLoading ? (
        <Spinner />
      ) : (
          <>
            <StatsCard
              data-testid="Potential threats"
              title="Potential threats"
              badgeText="Device security"
            >
              {potentialThreats}
            </StatsCard>

            <StatsCard
              data-testid="Threats blocked"
              title="Threats blocked"
              badgeText="Device security"
            >
              {threatsBlocked}
            </StatsCard>

            <StatsCard
              data-testid="Requests blocked"
              title="Requests blocked in the last 24 hours"
              badgeText="Internet security"
            >
              {blockedRequestCount}
            </StatsCard>
          </>
        )}
    </div>
  );
};

export default StatsContainer;
