export type AppConfig = {
  links: { downloads: Record<string, string | undefined> };
  sms: {
    activationTemplateName?: string;
    appDownloadTemplateName?: string;
  };
  anywhereExpert: {
    appKey?: string;
  };
};

const appConfig: AppConfig = {
  links: {
    downloads: {
      osx: process.env.REACT_APP_OSX_DOWNLOAD_LINK,
      windows: process.env.REACT_APP_WINDOWS_DOWNLOAD_LINK,
      ios: process.env.REACT_APP_IOS_APP_STORE_LINK,
      android: process.env.REACT_APP_GOOGLE_PLAY_STORE_LINK,
    },
  },
  sms: {
    activationTemplateName: process.env.REACT_APP_ACTIVATION_SMS_TEMPLATE_NAME,
    appDownloadTemplateName: process.env.REACT_APP_DOWNLOAD_SMS_TEMPLATE_NAME,
  },
  anywhereExpert: {
    appKey: process.env.REACT_APP_ANYWHERE_EXPERT_APP_KEY,
  },
};

export default appConfig;
