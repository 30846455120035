import React, { useState } from 'react';
import { default as styled } from 'styled-components';
import { ClickToCall, RoundedIcon } from '../../../common';
import { FAQ_PATH, WHATS_COVERED_PATH } from '../../../navigation';
import { useHistory } from 'react-router-dom';
import { Modal } from '../../../common';
import { useAnalytic } from '../../../analytics';
import breakpoints from '../../../common/helpers/breakpoints';

const Container = styled.div`
  display: flex;
  max-width: 30rem;
  justify-content: center;
  flex-wrap: wrap;
  color: white;

  @media (max-width: ${breakpoints.md}) {
    max-width: 25rem;
  }
`;

export const ActionIconGroup = () => {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const analytics = useAnalytic();

  const onClose = () => {
    setShowModal(false);
  };

  const openModalWithTracking = () => {
    return () => {
      analytics.dispatcher
        .createScoped('Dashboard Help Icons - Call Now BTN')
        .dispatch('Call Now Click');
      setShowModal(true);
    };
  };

  const navigateWithTracking = (path: string) => {
    return () => {
      analytics.dispatcher
        .createScoped('Dashboard Help Icons - FAQ')
        .dispatch('FAQ Link Click');
      history.push(path);
    };
  };

  const openChat = () => {
    (window.AE_SDK as {triggerMessagingOverlay: ()=>void}).triggerMessagingOverlay();
  };

  return (
    <Container data-testid='first-child-action-icon-group'>
      <Modal
        heading={'Connect with an Expert'}
        isOpen={showModal}
        onClose={onClose}
      >
        <ClickToCall>(844) 776-1960</ClickToCall>
      </Modal>

      <RoundedIcon iconName="chatBubble" text="Live chat" onClick={openChat} />
      <RoundedIcon
        iconName="phone"
        text="Call now"
        onClick={openModalWithTracking()}
      />
      <RoundedIcon
        iconName="shield"
        text="What's covered"
        onClick={() => history.push(WHATS_COVERED_PATH)}
      />
      <RoundedIcon
        iconName="questionBubble"
        text="FAQ"
        onClick={navigateWithTracking(FAQ_PATH)}
      />
    </Container>
  );
};
